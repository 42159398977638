import React, { useEffect, useState } from "react";
import { Box, Flex } from "components";
import { Accordion } from "components/Accordion";
import { TechBox } from "components/_post";
import { Button, Select } from "components/_form";
import { IOption } from "components/_form/Select/Select";
import { AddTaskModal } from "../components/AddTaskModal";
import { H3, P } from "components/Typography/Typography";
import { CodePlace } from "components/CodeEditor/CodeEditor";
import {
  languages,
  programmingLanguages,
} from "constants/programmingLanguages";
import { getPostStatus, updatePostStatus } from "services/post";
import { useParams } from "react-router-dom";
import { getUser } from "services/auth";
import { IUser } from "types/user";
import { toast } from "react-toastify";
import MDEditor from "@uiw/react-md-editor";
import { IUserPostStatus } from "types/post";

export const DoneTasksView = () => {
  const [responseData, setResponseData] = useState<IUserPostStatus | null>(
    null
  );
  const [candidate, setCandidate] = useState<IUser | null>(null);
  const [lang, setLang] = useState<languages>("" as languages);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  const { id, candidateId } = useParams<{ id: string; candidateId: string }>();

  const onLanguageChange = (val: IOption) => {
    if (!val) {
      setLang("" as languages);
      return;
    }
    setLang(val.value as languages);
  };

  const fetchUserPostTasks = async () => {
    if (candidateId && id) {
      const response = await getPostStatus({
        post_id: parseInt(id, 10),
        user_id: parseInt(candidateId, 10),
      });
      setResponseData(response.data);
    }
  };

  const fetchCandidateData = async (id: string) => {
    const { data } = await getUser(id);

    setCandidate(data);
  };

  const handleUpdatePostStatus = async (status: string) => {
    if (candidateId && id) {
      const { data } = await updatePostStatus(
        { user_id: parseInt(candidateId, 10), post_id: parseInt(id, 10) },
        { user_post_status: { status } }
      );

      data && toast.success("Zaktualizowano status aplikacji.");
    }
  };

  useEffect(() => {
    fetchUserPostTasks();
    candidateId && fetchCandidateData(candidateId);
  }, [id, candidateId]);

  return (
    <>
      <Flex flexWrap="wrap" mb={4} justifyContent="space-between">
        <Box>
          <H3 variant="h3" fontWeight={500} ml={3} mb={3}>
            O kandydacie
          </H3>

          <P variant="body" ml={3}>
            Tel. {candidate?.profile?.phone_number}
          </P>
          <P variant="body" ml={3}>
            Mail: {candidate?.email}
          </P>
          <P variant="body" ml={3}>
            {candidate?.profile?.town}
          </P>
        </Box>

        <Flex mt={25} ml={4} flexWrap="wrap">
          <Box mr={4} mb={3}>
            <Button
              variant="lightBlue"
              onClick={() => {
                handleUpdatePostStatus("reviewed");
              }}
            >
              Zatwierdź do nast. etapu
            </Button>
          </Box>
          <Box mb={3}>
            <Button
              variant="lightBlue"
              onClick={() => {
                handleUpdatePostStatus("cancelled");
              }}
            >
              Odrzuć
            </Button>
          </Box>
        </Flex>

        <Box width="200px">
          <Select
            label="Język"
            isClearable
            isMulti={false}
            options={programmingLanguages}
            onChange={onLanguageChange}
          />
        </Box>
      </Flex>

      <Flex flexDirection="column" flexWrap="wrap">
        {responseData?.user_solutions[0].post.tasks.map((task) => (
          <Flex m={2} key={task.id}>
            <Accordion
              title={task.name}
              customElement={<TechBox>{task.technology.name}</TechBox>}
            >
              <Box>
                <div data-color-mode="light">
                  <MDEditor.Markdown
                    style={{ padding: 10 }}
                    source={task.description}
                    linkTarget="_blank"
                  />
                </div>
              </Box>

              {responseData.user_solutions.map(
                ({ task_id, solution }) =>
                  task_id === task.id && (
                    <CodePlace
                      key={id}
                      theme="dracula"
                      language={task.technology.name.toLowerCase()}
                      displayName={task.technology.name}
                      value={solution}
                    />
                  )
              )}
            </Accordion>
          </Flex>
        ))}
      </Flex>
      <AddTaskModal
        key={+isTaskModalOpen}
        isOpen={isTaskModalOpen}
        onCancelClick={() => setIsTaskModalOpen(false)}
        onSubmitSuccess={() => setIsTaskModalOpen(false)}
      />
    </>
  );
};
