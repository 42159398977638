import React, { useEffect, useState } from "react";
import { Modal, IModal } from "components/Modal/Modal";
import { Controller, useForm } from "react-hook-form";
import { H2 } from "components/Typography/Typography";
import { Button, FieldLabel, Select, TextField } from "components/_form";
import { Flex } from "components/Flex";
import { Box } from "components/Box";
import { yupResolver } from "@hookform/resolvers/yup";
import { taskSchema } from "../../schemas/task";
import { languages } from "constants/programmingLanguages";
import { CodeEditor } from "components/CodeEditor";
import MDEditor from "@uiw/react-md-editor";
import { createTask, getTask, updateTask } from "services/task";
import { useAuthStateContext } from "contexts/AuthContext";
import { useFetchDictionaryTechnologies } from "hooks/useFetchDictionaryTechnologies";
import { ITask } from "types/task";
import { toast } from "react-toastify";

export const AddTaskModal: React.FC<
  IModal & { onSubmitSuccess?: (newTask?: ITask) => void; taskId?: number }
> = ({ isOpen, onCancelClick, onSubmitSuccess, taskId }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [solution, setSolution] = useState("");
  const { userProfile } = useAuthStateContext();
  const { dictionaryTechnologies } = useFetchDictionaryTechnologies();

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    register,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      technology: { value: "", label: "" },
    },
    resolver: yupResolver(taskSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError("");
    setIsLoading(true);

    const { technology } = data;

    if (userProfile) {
      if (taskId) {
        const res = await updateTask(taskId, {
          task: {
            ...data,
            solution,
            technology_id: parseInt(technology.value, 10),
          },
        });
        if (res.data.id) {
          onSubmitSuccess && onSubmitSuccess(res.data);
          toast.success("Zapisano zmiany");
        } else {
          toast.success("Coś poszło nie tak");
        }
        setIsLoading(false);
      } else {
        const res = await createTask({
          task: {
            ...data,
            solution,
            technology_id: parseInt(technology.value, 10),
            user_id: userProfile?.id,
          },
        });
        if (res.data.id) {
          onSubmitSuccess && onSubmitSuccess(res.data);
          toast.success("Dodano zadanie");
        } else {
          toast.success("Coś poszło nie tak");
        }
        setIsLoading(false);
      }
    }
  });

  useEffect(() => {
    taskId &&
      getTask(taskId).then((res) => {
        const { solution, name, description, technology } = res.data;
        setSolution(solution);
        reset({
          name,
          description,
          technology: {
            value: technology.id.toString(),
            label: technology.name,
          },
        });
      });
  }, [taskId]);

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <H2 variant="h2" color="dark" fontWeight={500} mb={3}>
        Dodaj nowe zadanie
      </H2>
      <form onSubmit={onSubmit}>
        <Box my={3}>
          <TextField
            label="Nazwa"
            {...register("name")}
            error={errors.name?.message}
          />
        </Box>
        <Box mb={3}>
          <FieldLabel>Opis</FieldLabel>

          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <div data-color-mode="light">
                <MDEditor height={200} value={value} onChange={onChange} />
              </div>
            )}
          />
        </Box>

        <Controller
          control={control}
          name="technology"
          render={({ field: { onChange, value } }) => (
            <Select
              label="Technologia"
              isMulti={false}
              options={dictionaryTechnologies || []}
              onChange={(val) => onChange(val)}
              selectedOptions={value}
              error={errors.technology?.value?.message}
            />
          )}
        />
        {watch("technology").value && (
          <CodeEditor
            language={
              (dictionaryTechnologies
                ?.find(({ value }) => value === watch("technology").value)
                ?.label.toLowerCase() || "python") as languages
            }
            customSetCode={setSolution}
            defaultValue={solution}
          />
        )}

        <Flex justifyContent="flex-end" my={3}>
          {isLoading ? (
            "Loading..."
          ) : (
            <Button variant="blue" type="submit">
              Zapisz
            </Button>
          )}
        </Flex>
      </form>
    </Modal>
  );
};
