import { H2 } from "components/Typography/Typography";
import { CaindidateAccordion } from "components/CaindidateAccordion/CaindidateAccordion";
import { Box } from "components/Box";
import { IUserPostStatus } from "types/post";

export const PostCandidateList = ({
  userPostStatuses,
  postId,
}: {
  userPostStatuses: IUserPostStatus[];
  postId?: number;
}) => {
  return (
    <Box mt={4}>
      <H2 variant="h2" fontWeight={500}>
        Lista kandydatów
      </H2>
      {userPostStatuses.map(({ user, status, cv, user_solutions }) => (
        <CaindidateAccordion
          key={user.id}
          candidate={user}
          status={status}
          userSolutions={user_solutions}
          postId={postId}
          cv={cv}
        />
      ))}
    </Box>
  );
};
