import MDEditor from "@uiw/react-md-editor";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPage } from "services/pages";

export const CustomView = () => {
  const [content, setContent] = useState("");
  const { slug } = useParams<{ slug: string }>();

  const getContent = async () => {
    if (slug) {
      const { data } = await getPage(slug);
      setContent(data?.markdown_content);
    }
  };

  useEffect(() => {
    getContent();
  }, [slug]);

  return (
    <div data-color-mode="light">
      <MDEditor.Markdown
        style={{ padding: 10 }}
        source={content}
        linkTarget="_blank"
      />
    </div>
  );
};
