// convert "dd.mm.yyyy hh:mm:ss" to "mm.dd.yyyy hh:mm:ss"

export const convertDate = (date: string) => {
  const splitedDate = date.split(" ");
  const mainDateArray = [...splitedDate[0].split(".")];

  return `${mainDateArray[1]}.${mainDateArray[0]}.${mainDateArray[2]} ${splitedDate[1]}`;
};

export const formatDate = (d: Date, char?: string) => d ? `${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${d.getFullYear()}` : '';
export const formatDateConstructable = (d: Date, char?: string) => d ? `${d.getFullYear()}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}` : '';
