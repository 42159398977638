import { REGULATION, REGULATIONS } from "constants/paths";
import { IRegulation, IRegulationRes } from "types/regulations";
import { http } from "utilities/http";

export const getRegulations = () =>
  http<IRegulationRes>(REGULATIONS, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const getRegulation = (id: string) =>
  http<{ data: IRegulation }>(REGULATION(id), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
