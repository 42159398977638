import React, { useState } from "react";
import { H1 } from "components/Typography/Typography";
import { Footer } from "views/layouts/components/Footer";
import { Card } from "components";
import {
  FooterContent,
  Logo,
  LogoContent,
  ViewContent,
  Wrapper,
} from "./GuestLayout.styled";
import { Navbar } from "../App/components/Navbar";
import { SideBar } from "../App/components/Sidebar";
import { useFetchPages } from "hooks/useFetchPages";

export const GuestLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isLoggedIn = !!localStorage.getItem("token");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const pages = useFetchPages();

  return (
    <Wrapper>
      {isSidebarOpen && (
        <SideBar
          logged={isLoggedIn}
          isSidebarOpen={isSidebarOpen}
          sidebarItems={[...(pages || [])]}
          onMenuIconClick={() => setIsSidebarOpen((prevState) => !prevState)}
        />
      )}
      <Navbar
        logged={isLoggedIn}
        isSidebarOpen={isSidebarOpen}
        onMenuIconClick={() => setIsSidebarOpen((prevState) => !prevState)}
      />
      <LogoContent>
        <H1 color="white" variant="h1">
          Automatyczna ocena kodu źródłowego
        </H1>
        <Logo />
      </LogoContent>
      <FooterContent>
        <Footer />
      </FooterContent>
      <ViewContent>
        <Card p={4}>{children}</Card>
      </ViewContent>
    </Wrapper>
  );
};
