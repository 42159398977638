import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email jest wymagany")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Niepoprawny format email"
    ),
  password: yup.string().min(6).max(128).required("Hasło jest wymagane"),
});

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email jest wymagany")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Niepoprawny format email"
    ),
  password: yup
    .string()
    .min(6, "Hasło powinno zawierać przynajmniej 6 znaków.")
    .max(18, "Hasło może zawierać maksymalnie 18 znaków.")
    .required("Hasło jest wymagane"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Hasła nie są takie same."),
  first_name: yup.string(),
  last_name: yup.string(),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Hasło powinno zawierać przynajmniej 6 znaków.")
    .max(18, "Hasło może zawierać maksymalnie 18 znaków.")
    .required("Hasło jest wymagane"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Hasła nie są takie same."),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email jest wymagany")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Niepoprawny format email"
    ),
});
