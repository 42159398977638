import { rgba } from "utilities/rgba";

export const device = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 992px)`,
  desktop: `(max-width: 1200px)`,
};

const palettePrimaryMain = "#08bbff";

export const theme = {
  palette: {
    primary: {
      main: palettePrimaryMain,
      secondary: "#086eab",
      dark: "#0f2a46",
    },
    neutral: {
      mainBlue: "#086eab",
      cardBg: "#dbe3e7",
      dark: "#0f2a46",
      grey: "#6A7482",
      lightGrey: "#E2E2E2",
      lightBlue: "#d6f2fb",
      light: "#EFEFEF",
      white: "#FFFFFF",
      veryLight: "#F6F6F6",
      medium: "#BEC9D5",
      rose: "#D91171",
    },
    accent: {
      yellow: "#F5D73F",
      orange: "#F5843F",
      lightBlue: "#0efff9",
      blue: "#76b7e5",
      red: "#FB5C00",
      green: "#D09B34",
      lightGreen: "#FFF4DE",
      purple: "rgba(255, 255, 255, 0.05)",
    },
    text: {
      primary: "rgb(19, 19, 21)",
      secondary: "#0f2a46",
    },
    background: "#E2E2E2",
  },
  textStyles: {
    body2: {
      fontSize: 12,
      lineHeight: "16px",
    },
    body: {
      fontSize: 13,
      lineHeight: "20px",
    },
    h4: {
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 700,
    },
    h3: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      lineHeight: "22px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 32,
      lineHeight: "36px",
      fontWeight: 700,
    },
  },
};
