import React, { useCallback, useEffect, useRef, useState } from "react";
import { Flex, Card, Box, Modal, CodeEditor } from "components";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthStateContext } from "contexts/AuthContext";
import { Button } from "components/_form";
import { H2, P } from "components/Typography/Typography";
import { IPost } from "types/post";
import { createPostStatus, getPost, postUserSolutions } from "services/post";
import { MainInfo } from "components/_post/MainInfo";
import { Accordion } from "components/Accordion";
import { TechBox } from "components/_post";
import { languages } from "constants/programmingLanguages";
import MDEditor from "@uiw/react-md-editor";
import { toast } from "react-toastify";

export interface IPostView {
  horizontal?: boolean;
  waiting?: boolean;
  pending?: boolean;
}

export const ApplyingPostView = ({
  horizontal = true,
}: IPostView): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [tasksCode, setTasksCode] = useState<{ [key: string]: string }>({});
  const [uploadedFile, setUploadedFile] = useState<File>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const parsedId = parseInt(id as string, 10);

  const [responseData, setResponseData] = useState<IPost["data"] | null>(null);

  const { userProfile } = useAuthStateContext();

  const fetchPost = useCallback(
    async (id: number) => {
      setError("");
      setIsLoading(true);
      try {
        const response = await getPost(id, !userProfile);
        if (!response) return;
        setResponseData(response.data);
      } catch (err: any) {
        setError(err.error);
      } finally {
        setIsLoading(false);
      }
    },
    [id]
  );

  const submitApply = async () => {
    if (userProfile?.id && parsedId && uploadedFile) {
      const postApplication = {
        user_post_status: {
          user_id: userProfile.id,
          post_id: parsedId,
          status: "pending",
          cv: uploadedFile,
        },
      };

      const res = await createPostStatus(postApplication);

      Object.keys(tasksCode).map(async (key) => {
        postUserSolutions({
          user_solution: {
            task_id: key,
            user_id: userProfile.id,
            solution: tasksCode[key],
            user_post_status_id: res.data.id,
          },
        });
      });

      navigate(`../../../app/your-applies/${id}/details`);
    } else if (!uploadedFile) {
      toast.info("Dodaj CV, żeby zaaplikować");
    }
  };

  useEffect(() => {
    fetchPost(parsedId);
  }, []);

  return (
    <>
      <Card>
        <Flex flexDirection="column">
          <MainInfo responseData={responseData} />

          <Flex justifyContent="end" mt={3}>
            <Button
              fullWidth
              variant="lightBlue"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current?.click();
              }}
            >
              {uploadedFile ? uploadedFile.name : "Prześlij CV"}
            </Button>

            <input
              type="file"
              multiple
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(event) => {
                if (event.target.files) {
                  setUploadedFile(event.target.files[0]);
                }
              }}
            />
          </Flex>

          <H2 variant="h2" fontWeight={500} mt={3}>
            Rozwiąż zadania rekrutacyjne
          </H2>
          {responseData?.tasks.map(
            ({ id, name = `Zadanie ${id}`, description, technology }) => (
              <Flex key={id} m={2}>
                <Accordion
                  key={id}
                  title={name}
                  customElement={<TechBox>{technology?.name}</TechBox>}
                >
                  <Box>
                    <div data-color-mode="light">
                      <MDEditor.Markdown
                        style={{ padding: 10 }}
                        source={description}
                        linkTarget="_blank"
                      />
                    </div>
                  </Box>

                  <Box mt={3}>
                    <CodeEditor
                      language={
                        (technology?.name?.toLowerCase() ||
                          "python") as languages
                      }
                      customSetCode={(value: string) => {
                        setTasksCode((prevTasksCode) => ({
                          ...prevTasksCode,
                          [id]: value,
                        }));
                      }}
                    />
                  </Box>
                </Accordion>
              </Flex>
            )
          )}

          <Flex justifyContent="end" mt={3}>
            <Button variant="lightBlue" onClick={() => setIsModalOpen(true)}>
              Zapisz
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Modal isOpen={isModalOpen} onCancelClick={() => setIsModalOpen(false)}>
        <Box>
          <P variant="h3" fontWeight={400}>
            Czy chcesz zapisać swoją aplikację?
          </P>
          <Flex justifyContent="end" mt={3}>
            <Box mr={2}>
              <Button onClick={() => setIsModalOpen(false)}>Nie</Button>
            </Box>
            <Button onClick={submitApply}>Tak</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};
