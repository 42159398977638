import { useAuthDispatchContext } from "contexts/AuthContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/lock-password-fill.svg";
import { registerUser } from "services/auth";
import styled from "styled-components";
import { Box, Flex, Link, Typography } from "components";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { Button, Checkbox, Select, TextField } from "components/_form";
import { IRegister } from "../../types/forms/auth";
import { registerSchema } from "schemas/auth";
import { Spinner } from "components/Spinner";
import { ErrorText } from "components/_form/ErrorText";
import { UserRoleType } from "types/user";
import { getRegulations } from "services/regulations";
import { IRegulation } from "types/regulations";

interface IRegisterFormData {
  email: string;
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
  newsletterAgreement: boolean;
  role: { value: UserRoleType; label: string };
  regulation_ids: number[];
}

const Wrapper = styled(Flex)`
  //@media only screen and (min-width: 980px) {
  //  width: 322px;
  //}
`;

const StyledBoxWrapper = styled(Box)`
  width: 100%;

  > label {
    cursor: pointer;
    ${({ theme }) => `
      color: ${theme.palette.neutral.grey};
    `};
  }

  @media only screen and (max-width: 980px) {
    width: 90%;
  }
`;

const initialValues = {
  email: "",
  password: "",
  password_confirmation: "",
  first_name: "",
  last_name: "",
  role: { value: "candidate" as "candidate", label: "Kandydat" },
  regulation_ids: [],
};

export const RegisterView = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, setUserProfile } = useAuthDispatchContext();
  const [regulations, setRegulations] = useState<IRegulation[]>();

  const signup = async (data: IRegister) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await registerUser(data);
      navigate("/app");
      if (!response.token) return;
      setToken(response.token);
      setUserProfile(response);
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    control,
    watch,
  } = useForm<IRegisterFormData>({
    mode: "onChange",
    resolver: yupResolver(registerSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (data: IRegisterFormData) => {
    const { role, ...rest } = data;

    signup({ ...rest, role: role.value });
  };

  const loadRegulations = async () => {
    const { data } = await getRegulations();
    setRegulations(data);
  };

  useEffect(() => {
    loadRegulations();
  }, []);

  return (
    <Wrapper flexDirection="column" justifyContent="center">
      <Typography.H2
        variant="h2"
        color="primary"
        textAlign="center"
        fontWeight={600}
        mb={11}
      >
        Zarejestruj
      </Typography.H2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledBoxWrapper mb={3}>
          <TextField
            label="Email"
            transparent
            icon={<UserIcon />}
            {...register("email")}
            error={errors.email?.message}
          />
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={2}>
          <TextField
            type="password"
            label="Hasło"
            transparent
            icon={<PasswordIcon />}
            {...register("password")}
            error={errors.password?.message}
          />
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={2}>
          <TextField
            type="password"
            label="Powtórz hasło"
            transparent
            icon={<PasswordIcon />}
            {...register("password_confirmation")}
            error={errors.password_confirmation?.message}
          />
        </StyledBoxWrapper>

        <StyledBoxWrapper mb={3}>
          <Controller
            control={control}
            name="role"
            render={({ field: { onChange } }) => (
              <Select
                label="Typ konta"
                options={[
                  { value: "company", label: "Firma" },
                  { value: "candidate", label: "Kandydat" },
                ]}
                isMulti={false}
                onChange={(val) => onChange(val)}
              />
            )}
          />
        </StyledBoxWrapper>

        {watch("role").value === "candidate" ? (
          <>
            <StyledBoxWrapper mb={3}>
              <TextField
                label="Imię"
                transparent
                icon={<UserIcon />}
                {...register("first_name")}
                error={errors.first_name?.message}
              />
            </StyledBoxWrapper>
            <StyledBoxWrapper mb={3}>
              <TextField
                label="Nazwisko"
                transparent
                icon={<UserIcon />}
                {...register("last_name")}
                error={errors.last_name?.message}
              />
            </StyledBoxWrapper>
          </>
        ) : (
          <StyledBoxWrapper mb={3}>
            <TextField
              label="Nazwa"
              transparent
              icon={<UserIcon />}
              {...register("first_name")}
              error={errors.first_name?.message}
            />
          </StyledBoxWrapper>
        )}

        <Controller
          control={control}
          name="regulation_ids"
          render={({ field: { onChange } }) => (
            <>
              {regulations?.map((regulation) => (
                <StyledBoxWrapper
                  display="flex"
                  justifyContent="start"
                  mb={4}
                  alignItems="center"
                  key={regulation.id}
                >
                  <Checkbox
                    onChange={(newValue) => {
                      const checked = newValue.target.checked;
                      const currentValue = watch("regulation_ids");

                      onChange(
                        checked
                          ? currentValue.includes(regulation.id)
                            ? currentValue
                            : [...currentValue, regulation.id]
                          : currentValue.filter(
                              (item) => item !== regulation.id
                            )
                      );
                    }}
                  />
                  <Link to={`/info/${regulation.link}`} target="_blank">
                    {regulation.name}
                  </Link>
                </StyledBoxWrapper>
              ))}
            </>
          )}
        />

        <StyledBoxWrapper
          display="flex"
          justifyContent="start"
          alignItems="center"
          mt={2}
        />

        {error && (
          <ErrorText>
            {error ===
            "Users regulations have to be present for all current required regulations."
              ? "Zaznacz wymagane zgody"
              : error}
          </ErrorText>
        )}

        <Box my={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              variant="blue"
              fullWidth
              type="submit"
              disabled={!formState.isValid}
            >
              Zarejestruj
            </Button>
          )}
        </Box>
        <Flex>
          <Typography.P variant="body" color="primary" mr={1}>
            Masz już konto?
          </Typography.P>
          <Link to="/">
            <Typography.P variant="h3" color="coloured">
              Zaloguj
            </Typography.P>
          </Link>
        </Flex>
      </form>
    </Wrapper>
  );
};
