import { ColouredBox } from "components/ColouredBox";
import { Flex } from "components/Flex";
import { H4, P } from "components/Typography/Typography";
import { device } from "config/theme";
import { API_PHOTO_URL } from "constants/paths";
import { useState } from "react";
import styled from "styled-components";
import { IPost } from "types/post";
import { TechBox } from "./TechBox";

const FlexWrapper = styled(Flex)`
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

const Image = styled.img`
  border-radius: 4px;
  width: 30%;
  margin-right: 12px;
  cursor: pointer;

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const MainInfo = ({
  responseData,
}: {
  responseData: IPost["data"] | null;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <FlexWrapper alignItems="flex-start">
      <Image src={`${API_PHOTO_URL}${responseData?.photo}`} />
      <ColouredBox p={3} width="100%">
        <H4 variant="h3" fontWeight={600}>
          {responseData?.name} - {responseData?.job_title}
        </H4>
        <FlexWrapper justifyContent="space-between" mt={2}>
          <P variant="body" color="secondary">
            Lokalizacja: {responseData?.address}
          </P>
          <P variant="body" color="secondary">
            Widełki: {responseData?.min_range_salary} -{" "}
            {responseData?.max_range_salary} PLN
          </P>
          <P variant="body" color="secondary">
            Tryb pracy: {responseData?.job_mode}
          </P>
        </FlexWrapper>
        {isExpanded && (
          <>
            <H4 variant="h3" fontWeight={600} mt={2}>
              Wymagane technologie
            </H4>
            <Flex mt={2}>
              {responseData?.technologies?.map(({ id, name }) => (
                <TechBox key={id} p={2} mr={2}>
                  {name}
                </TechBox>
              ))}
            </Flex>
            <Flex>
              <P variant="body" mt={3} mr={3} color="secondary">
                Start: {responseData?.start_at}
              </P>
              <P variant="body" mt={3} color="secondary">
                Koniec: {responseData?.finish_at}
              </P>
            </Flex>
          </>
        )}
        <Flex justifyContent="end">
          <P
            style={{ cursor: "pointer" }}
            onClick={() => setIsExpanded((prevState) => !prevState)}
            variant="body"
            color="secondary"
            mt={3}
          >
            {isExpanded ? "zwiń" : "rozwiń"}
          </P>
        </Flex>
      </ColouredBox>
    </FlexWrapper>
  );
};
