import React from "react";
import styled from "styled-components";
import { Typography, Flex, Link } from "components";
import { ReactComponent as YouTubeIcon } from "assets/icons/ytube.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedIn.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/fbook.svg";

const Wrapper = styled(Flex)`
  ${({ theme }) => `
    // background-color: ${theme.palette.primary.main};
  `}
  width: 172px;
  a {
    text-decoration: none;
  }

  flex-direction: column;
`;

const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  ${({ theme }) => `background-color: ${theme.palette.neutral.white}`}
`;

export const Footer = () => {
  return (
    <Wrapper>
      <Flex justifyContent="space-between">
        <Link to="/info/about">
          <Typography.P variant="body" color="white">
            O NAS
          </Typography.P>
        </Link>
        <VerticalLine />
        <Link to="/info/contact">
          <Typography.P variant="body" color="white">
            KONTAKT
          </Typography.P>
        </Link>
      </Flex>
      <Flex mt={4} justifyContent="space-between">
        <a
          href="https://www.youtube.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon />
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://www.linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </a>
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
      </Flex>
    </Wrapper>
  );
};
