import {Flex, Card, Typography, Link, Box} from "components";
import {ReactComponent as EmailIcon} from "assets/icons/user.svg";
import {ReactComponent as PasswordIcon} from "assets/icons/lock-password-fill.svg";
import {useAuthDispatchContext} from "contexts/AuthContext";
import {useForm} from "react-hook-form";
import {loginUser} from "services/auth";
import {Button, TextField} from "components/_form";
import {yupResolver} from "@hookform/resolvers/yup";
import {loginSchema} from "schemas/auth";
import {useNavigate} from "react-router";
import {useState} from "react";
import {Spinner} from "components/Spinner";
import {ErrorText} from "components/_form/ErrorText";

interface ILoginFormData {
  email: string;
  password: string;
}

export const LoginView = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {setToken, setUserProfile} = useAuthDispatchContext();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<ILoginFormData>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await loginUser(data);
      if (!response) return;
      setToken(response.token);
      setUserProfile(response);
      navigate("/app");
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography.H2
        variant="h2"
        color="primary"
        textAlign="center"
        fontWeight={600}
        mb={11}
      >
        Zaloguj
      </Typography.H2>
      <form onSubmit={onSubmit}>
        <TextField
          label="Email"
          transparent
          icon={<EmailIcon/>}
          {...register("email")}
          error={errors.email?.message}
        />
        <Box mt={4} mb={2}>
          <TextField
            type="password"
            label="Hasło"
            transparent
            icon={<PasswordIcon/>}
            {...register("password")}
            error={errors.password?.message}
          />
        </Box>

        <Box display="flex" justifyContent="end">
          <Link to="/password-reset">Zapomniałeś hasła?</Link>
        </Box>

        {error && <ErrorText>{error}</ErrorText>}

        <Box my={4}>
          {isLoading
            ? <Spinner/>
            : <Button
              variant="blue"
              fullWidth
              type="submit"
              disabled={!formState.isValid}
            >
              Zaloguj
            </Button>
          }
        </Box>
      </form>
      <Flex>
        <Typography.P variant="body" color="primary" mr={1}>
          Nie masz konta?
        </Typography.P>
        <Link to="/signup">
          <Typography.H4 variant="h3" color="coloured">
            Zarejestruj
          </Typography.H4>
        </Link>
      </Flex>
    </Flex>
  );
};
