import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { device } from "config/theme";
import styled from "styled-components";

interface IStyledFlex {
  isOpen: boolean;
}

export const StyledCard = styled(Card)<{ isOpen?: boolean }>`
  padding: 12px 20px;
  cursor: pointer;

  ${({ theme, isOpen }) =>
    isOpen
      ? `
        border-radius: 6px 6px 0 0;
        color: ${theme.palette.neutral.white};
        background-color: ${theme.palette.neutral.mainBlue};
      `
      : ""}
`;

export const StyledFlex = styled(Flex)<IStyledFlex>`
  white-space: pre-wrap;
  ${({ isOpen }) => `
    display: ${isOpen ? "block" : "none"};

    @media ${device.tablet} {
      padding: 0;
    }
  `};
`;
