import { useAuthStateContext } from "contexts/AuthContext";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { token } = useAuthStateContext();
  if (!token) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};
