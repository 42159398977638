import React, { useEffect, useState } from "react";
import { Flex } from "components/Flex";
import styled from "styled-components";
import {
  LinkProps,
  NavLink,
  useLocation,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
// import { useAbsolutePath } from "hooks/useAbsolutePath";

export interface IMenuItem {
  path: string;
  title: string;
  private: boolean;
  type?: string;
}
export interface IMenu {
  items?: Array<IMenuItem>;
  onMenuIconClick: () => void;
  view?: "horizontal" | "vertical";
  color?: string;
}
interface IListItem {
  active?: boolean;
  color?: string;
}
export interface ILink {
  to: LinkProps["to"];
}

const NavigationLink = styled(NavLink)`
  text-align: center;
  ${({ theme }) => `
    display: block;
    color: ${theme.palette.text.primary};
    text-decoration: none;
    font-size: 14px;
  `};
`;
const ListItem = styled(Flex)<IListItem>`
  ${({ theme, active, color }) => `
  height: 43px;
  background-color: ${active && theme.palette.neutral.white}; 
  color: ${
    active
      ? theme.palette.neutral.mainBlue
      : color || theme.palette.neutral.dark
  };     

      &:hover {    
        font-weight: 500;
        background-color: ${theme.palette.neutral.lightBlue};        
        color: ${theme.palette.neutral.mainBlue};     
      }
  `}
`;

export const Menu = ({
  items,
  onMenuIconClick,
  view = "horizontal",
  color,
}: IMenu) => {
  const { pathname } = useLocation();
  const [active, setIsActive] = useState<string>(pathname || "");

  const handleOnClick = (path: string) => {
    onMenuIconClick();
    setIsActive(path);
  };

  const renderItems = () => {
    if (!items) return;
    return items.map(({ path, title }) => {
      const isItemSelected = active === path;

      return (
        <NavigationLink to={path} key={title}>
          <ListItem
            active={isItemSelected}
            px={[1, 4]}
            mx={2}
            alignItems="center"
            justifyContent="center"
            onClick={() => handleOnClick(path)}
            color={color}
          >
            {title}
          </ListItem>
        </NavigationLink>
      );
    });
  };

  return (
    <Flex width="100%" flexDirection={view === "horizontal" ? "column" : "row"}>
      {renderItems()}
    </Flex>
  );
};
