import React, { useEffect, useState } from "react";
import { Box, Flex, Line } from "components";
import styled from "styled-components";
// import {AccountTypeToggle} from "components/AccountTypeToggle/AccountTypeToggle";
import { device } from "config/theme";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MenuIcon } from "assets/icons/bx-x.svg";
import { useAuthStateContext } from "contexts/AuthContext";
import { Footer } from "views/layouts/components/Footer";
import { IMenuItem, Menu } from "components/Menu/Menu";
import Logo from "assets/img/logo_dados.png";

interface ISideBar {
  logged: boolean;
  onMenuIconClick: () => void;
  isSidebarOpen: boolean;
  sidebarItems: Array<IMenuItem>;
}

const Wrapper = styled(Flex)<{ isSidebarOpen: boolean }>`
  transition: all 0.4s ease-in-out;
  z-index: 1000;
  position: relative;
  grid-area: sidebar;

  //background: linear-gradient(180deg, #0460a5 0%, #6911d9 100%),
  //  linear-gradient(180deg, #0460a5 0%, #0087c0 100%), #ffffff;
  background-color: ${({theme}) => theme.palette.primary.secondary};
  background-blend-mode: overlay, normal, normal, normal;
  ${({ theme }) => `
    // background-color: ${theme.palette.neutral.white};
    box-shadow: 0 0px 12px  ${theme.palette.neutral.medium};
  `}

  @media ${device.tablet} {
    position: absolute;
    height: 100%;
    width: 60%;
    ${({ isSidebarOpen }) => `
       margin-left: ${isSidebarOpen ? "0px" : "-100%"};
   `}
  }
`;
const Image = styled.img`
  //padding-top: 4px;
  width: 100%;
  height: 70px;
  cursor: pointer;
`;
const MenuToggleIcon = styled(MenuIcon)`
  align-self: flex-start;
  padding: 10px;
  height: ${`calc(100% / 12 + 2px)`};
  margin-left: 14px;
  width: 40px;
  cursor: pointer;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

const FooterWrapper = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.accent.purple};
  `}
`;

export const SideBar = ({
  logged,
  onMenuIconClick,
  isSidebarOpen,
  sidebarItems,
}: ISideBar) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState<Array<IMenuItem>>([]);

  const { userProfile } = useAuthStateContext();

  const privatePathGuard = (
    isLogged: boolean,
    itemsToCheck: Array<IMenuItem>
  ) => {
    const filteredMenuItems = itemsToCheck.filter(
      ({ type }) => type === userProfile?.role || !type
    );
    isLogged
      ? setMenu(filteredMenuItems)
      : setMenu(filteredMenuItems.filter((item) => isLogged === item.private));
  };

  useEffect(() => {
    privatePathGuard(logged, sidebarItems);
  }, [logged, userProfile?.role, sidebarItems]);

  return (
    <Wrapper
      isSidebarOpen={isSidebarOpen}
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex alignItems="center" flexDirection="column">
        <MenuToggleIcon onClick={onMenuIconClick} />
        <Image
          src={Logo}
          onClick={() => navigate("/app")}
        />
        <Box mt={4}>
          <Menu onMenuIconClick={onMenuIconClick} items={menu} color="white" />
        </Box>
      </Flex>
      <FooterWrapper
        py={4}
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Footer />
      </FooterWrapper>
    </Wrapper>
  );
};
