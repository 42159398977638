import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";

export const TechBox = styled.div<SpaceProps>(
  () => css`
    align-self: flex-start;
    padding: 6px;
    margin: 2px;
    border-radius: 8px;
    ${({ theme }) => `
      background-color: ${theme.palette.neutral.mainBlue}}
      color: ${theme.palette.neutral.white}}
    `}
  `,
  space
);
