import React, { useEffect, useRef, useState } from "react";
import { Flex, Card, Box } from "components";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import {
  TextareaField,
  FieldLabel,
  TextField,
  Button,
  Select,
  Checkbox,
} from "components/_form/";
import { Accordion } from "components/Accordion/Accordion";
import { H2, H4, P } from "components/Typography/Typography";
import { CustomDatePicker } from "components/CustomDatePicker";
import { createPost, getPost, updatePost } from "services/post";
import { useNavigate, useParams } from "react-router";
import { device } from "config/theme";
import { AddTaskModal } from "components/AddTaskModal";
import { TechBox } from "components/_post";
import { useFetchDictionaryTechnologies } from "hooks/useFetchDictionaryTechnologies";
import { jobModeTranslation } from "constants/jobModeTranslation";
import { API_PHOTO_URL } from "constants/paths";
import { convertDate } from "utilities/convertDate";
import MDEditor from "@uiw/react-md-editor";
import { getTasks } from "services/task";
import { ITask } from "types/task";
import { useAuthStateContext } from "contexts/AuthContext";

const ColouredBox = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.veryLight}
  `}
`;

const ResponsiveColouredBox = styled(ColouredBox)`
  width: 50%;

  @media ${device.tablet} {
    width: 100%;
  }
`;

const ColouredTextBlock = styled(Box)`
  ${({ theme }) => `
    height: 37px;
    background-color: ${theme.palette.neutral.lightGrey}
  `}
`;

const SelectBox = styled(Box)`
  width: 30%;

  @media ${device.tablet} {
    width: 100%;
  }
`;

const MobileHideBox = styled(Box)`
  @media ${device.tablet} {
    display: none;
  }
`;

const Image = styled.div<{ url: string }>`
  ${({ url }) => `
    width: 300px;
    height: 300px;
    background-color: transparent;
    ${url && `background-image: url('${url}');`}
    background-position: center;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;

    @media ${device.tablet} {
      width: 180px;
      height: 180px;
    }
  `}
`;

const postStatusValues = [
  { value: "draft", label: "wersja robocza" },
  { value: "closed", label: "zamknięta rekrutacja" },
  { value: "opened", label: "otwarta rekrutacja" },
];

export const PostFormView = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [preview, setPreview] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [ownTasks, setOwnTasks] = useState<ITask[]>([]);
  const { userProfile } = useAuthStateContext();

  const { id } = useParams<{ id: string }>();
  const parsedId = parseInt(id as string, 10);

  const { formState, handleSubmit, control, watch, register, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      job_title: "",
      start_at: new Date(),
      finish_at: new Date(),
      salary_min: 0,
      salary_max: 0,
      address: "",
      job_mode: { value: "", label: "" },
      status: { value: "", label: "" },
      technology_ids: [{ value: "", label: "" }],
      responsibilities: "",
      offer: "",
      description: "",
      selectedTasks: [{ checked: false }],
      selectedOwnTasks: [{ checked: false }],
    },
  });

  const navigate = useNavigate();

  const { dictionaryTechnologies } = useFetchDictionaryTechnologies();

  const onSubmit = handleSubmit(async (data) => {
    setError("");
    setIsLoading(true);

    const {
      salary_min,
      salary_max,
      job_mode,
      status,
      technology_ids,
      selectedTasks,
      selectedOwnTasks,
      ...rest
    } = data;

    const reqPayload = {
      ...rest,
      min_range_salary: salary_min,
      max_range_salary: salary_max,
      job_mode: job_mode.value,
      status: status.value,
      technology_ids: technology_ids.map(({ value }) => value),
      photo: uploadedFile,
      task_ids: [
        ...tasks.map((item, index) => {
          if (selectedTasks[index].checked) return item.id;
          return;
        }),
        ...ownTasks.map((item, index) => {
          if (selectedOwnTasks[index].checked) return item.id;
          return;
        }),
      ].filter((item) => item !== undefined),
    };

    try {
      if (id) {
        const response = await updatePost(id, { post: reqPayload });
        if (!response) return;
      } else {
        const response = await createPost({ post: reqPayload });
        if (!response) return;
      }
      if (userProfile?.role === "admin") {
        navigate("/app/offers");
      } else {
        navigate("/app/my-offers");
      }
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  });

  const loadEditingPostValue = async (id: number) => {
    const { data } = await getPost(id);

    const dataToReset = {
      name: data.name,
      job_title: data.job_title,
      address: data.address,
      responsibilities: data.responsibilities,
      offer: data.offer,
      description: data.description,
      start_at: new Date(convertDate(data.start_at)),
      finish_at: new Date(convertDate(data.finish_at)),
      job_mode: jobModeTranslation[data.job_mode],
      status: postStatusValues.find(({ value }) => value === data.status),
      salary_min: data.min_range_salary || 0,
      salary_max: data.max_range_salary || 0,
      ...(dictionaryTechnologies && {
        technology_ids: dictionaryTechnologies.filter(({ value }) =>
          [...data.technologies.map((item) => item.id.toString())].includes(
            value
          )
        ),
      }),
      selectedTasks: tasks.map(({ id }) =>
        data.tasks.find((searchingTask) => searchingTask.id === id)
          ? { checked: true }
          : { checked: false }
      ),
      selectedOwnTasks: ownTasks.map(({ id }) =>
        data.tasks.find((searchingTask) => searchingTask.id === id)
          ? { checked: true }
          : { checked: false }
      ),
    };

    setPreview(`${API_PHOTO_URL}${data.photo}`);
    reset(dataToReset);
  };

  useEffect(() => {
    if (formState.isValid === formState.isDirty) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [formState]);

  useEffect(() => {
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      setPreview("");
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (parsedId) {
      loadEditingPostValue(parsedId);
    }
  }, [parsedId, dictionaryTechnologies?.length]);

  useEffect(() => {
    getTasks({ filter: "company_tasks" }).then((res) => {
      setOwnTasks(res.data);
    });
    getTasks({ filter: "default_tasks" }).then((res) => {
      setTasks(res.data);
    });
  }, []);

  return (
    <>
      <Card>
        <H2 variant="h2" color="dark" fontWeight={500} mb={3}>
          Dodaj nowe ogłoszenie
        </H2>

        <form onSubmit={onSubmit}>
          <ColouredBox p={3} mb={3}>
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              flexWrap="wrap"
            >
              {preview && <Image url={preview} />}
              <Box mt={3}>
                <Button
                  variant="lightBlue"
                  onClick={(event) => {
                    event.preventDefault();
                    fileInputRef.current?.click();
                  }}
                >
                  {uploadedFile ? uploadedFile.name : "Dodaj zdjęcie"}
                </Button>
              </Box>
            </Flex>

            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              multiple
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(event) => {
                if (event.target.files) {
                  setUploadedFile(event.target.files[0]);
                }
              }}
            />
          </ColouredBox>
          <ColouredBox p={3}>
            <Box my={3}>
              <TextField label="Nazwa ogłoszenia" {...register("name")} />
            </Box>

            <Flex flexDirection="column">
              <Flex mb={3} flexWrap="wrap">
                <Box mr={3}>
                  <TextField label="Stanowisko" {...register("job_title")} />
                </Box>
                <Flex flexDirection="column">
                  <FieldLabel>Czas trwania</FieldLabel>
                  <Flex>
                    <Controller
                      control={control}
                      name="start_at"
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker value={value} onChange={onChange} />
                      )}
                    />
                    <H2
                      variant="h2"
                      color="dark"
                      fontWeight={500}
                      mt={2}
                      mx={2}
                    >
                      -
                    </H2>
                    <Controller
                      control={control}
                      name="finish_at"
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker value={value} onChange={onChange} />
                      )}
                    />
                  </Flex>
                </Flex>
              </Flex>

              <Flex flexWrap="wrap">
                <Box mr={3}>
                  <TextField label="Lokalizacja" {...register("address")} />
                </Box>
                <Flex flexDirection="column">
                  <FieldLabel>Wynagrodzenie brutto</FieldLabel>
                  <Flex>
                    <TextField {...register("salary_min")} />
                    <H2
                      variant="h2"
                      color="dark"
                      fontWeight={500}
                      mt={2}
                      mx={2}
                    >
                      -
                    </H2>
                    <TextField {...register("salary_max")} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex mt={3} flexWrap="wrap">
              <SelectBox>
                <Controller
                  control={control}
                  name="job_mode"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Tryb pracy"
                      isMulti={false}
                      options={[
                        { value: "work_office", label: "stacjonarny" },
                        { value: "partly_remote", label: "hybrydowy" },
                        { value: "remote", label: "zdalny" },
                      ]}
                      onChange={(val) => onChange(val)}
                      selectedOptions={value}
                    />
                  )}
                />
              </SelectBox>
            </Flex>

            <Flex mt={3}>
              <SelectBox>
                <Controller
                  control={control}
                  name="technology_ids"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Wymagane technologie"
                      isMulti={true}
                      onChange={(val) => onChange(val)}
                      options={dictionaryTechnologies || []}
                      selectedOptions={value}
                    />
                  )}
                />
              </SelectBox>
              {watch("technology_ids")?.map(
                (item: { value: string; label: string }) =>
                  item.label && (
                    <ColouredTextBlock key={item.value} mt={4} ml={4} p={2}>
                      {item.label}
                    </ColouredTextBlock>
                  )
              )}
            </Flex>

            <Flex mt={3} flexWrap="wrap">
              <SelectBox>
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Status"
                      isMulti={false}
                      options={postStatusValues}
                      onChange={(val) => onChange(val)}
                      selectedOptions={value}
                    />
                  )}
                />
              </SelectBox>
            </Flex>
          </ColouredBox>

          <Flex mt={3} flexWrap="wrap">
            <ResponsiveColouredBox height="195px" p={3}>
              <TextareaField
                label="Wymagania / Obowiązki"
                bgColor="white"
                {...register("responsibilities")}
              />
            </ResponsiveColouredBox>
            <ResponsiveColouredBox height="195px" p={3}>
              <TextareaField
                label="Oferujemy"
                bgColor="white"
                {...register("offer")}
              />
            </ResponsiveColouredBox>
          </Flex>
          <ColouredBox height="150px" p={3} mt={3}>
            <TextareaField
              label="Opis stanowiska"
              bgColor="white"
              {...register("description")}
            />
          </ColouredBox>

          <ColouredBox py={2} mt={3}>
            {!!tasks.length && (
              <>
                <H4 ml={4} variant="h3" color="dark" fontWeight={400}>
                  Wybierz zadanie rekrutacyjne z systemu
                </H4>
                {tasks.map((el, index) => {
                  return (
                    <Controller
                      key={el.id}
                      control={control}
                      name={`selectedTasks.${index}.checked`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Flex alignItems="flex-start" m={2}>
                            <Box mt={3} pt={1} pl={3}>
                              <Checkbox onChange={onChange} checked={!!value} />
                            </Box>
                            <Accordion
                              title={el.name}
                              customElement={
                                <MobileHideBox>
                                  <TechBox>{el.technology.name}</TechBox>
                                </MobileHideBox>
                              }
                            >
                              <Box>
                                <div data-color-mode="light">
                                  <MDEditor.Markdown
                                    style={{ padding: 10 }}
                                    source={el.description}
                                    linkTarget="_blank"
                                  />
                                </div>
                              </Box>
                            </Accordion>
                          </Flex>
                        );
                      }}
                    />
                  );
                })}
              </>
            )}

            {!!ownTasks.length && (
              <>
                <H4 ml={4} variant="h3" color="dark" fontWeight={400}>
                  Wybierz zadanie rekrutacyjne ze swojej listy
                </H4>
                {ownTasks.map((el, index) => {
                  return (
                    <Controller
                      key={el.id}
                      control={control}
                      name={`selectedOwnTasks.${index}.checked`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Flex alignItems="flex-start" m={2}>
                            <Box mt={3} pt={1} pl={3}>
                              <Checkbox onChange={onChange} checked={!!value} />
                            </Box>
                            <Accordion
                              title={el.name}
                              customElement={
                                <MobileHideBox>
                                  <TechBox>{el.technology.name}</TechBox>
                                </MobileHideBox>
                              }
                            >
                              <Box>
                                <div data-color-mode="light">
                                  <MDEditor.Markdown
                                    style={{ padding: 10 }}
                                    source={el.description}
                                    linkTarget="_blank"
                                  />
                                </div>
                              </Box>
                            </Accordion>
                          </Flex>
                        );
                      }}
                    />
                  );
                })}
              </>
            )}

            <Flex justifyContent="flex-end" pr={4} pb={2} mt={3}>
              <Button variant="blue" onClick={() => setIsTaskModalOpen(true)}>
                Dodaj zadanie
              </Button>
            </Flex>
          </ColouredBox>

          <Flex justifyContent="flex-end" m={4}>
            <P variant="body" color="red">
              {error}
            </P>
          </Flex>

          <Flex justifyContent="flex-end" mb={30} mr={14} mt={4}>
            {isLoading ? (
              "Loading..."
            ) : (
              <Button variant="blue" type="submit">
                Zapisz
              </Button>
            )}
          </Flex>
        </form>
      </Card>
      <AddTaskModal
        isOpen={isTaskModalOpen}
        onCancelClick={() => setIsTaskModalOpen(false)}
        onSubmitSuccess={(newTask) => {
          setIsTaskModalOpen(false);
          newTask && setOwnTasks((prevOwnTasks) => [newTask, ...prevOwnTasks]);
        }}
      />
    </>
  );
};
