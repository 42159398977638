import { http } from "utilities/http";
import {
  LOGIN_URL,
  LOGOUT_URL,
  PASSWORD_RESET,
  PROFILE,
  REGISTER_URL,
  USER,
} from "constants/paths";
import {
  IChangePassword,
  ILogin,
  IRegister,
  IResetPassword,
} from "types/forms/auth";
import { IUser, IUserWithToken } from "types/user";
import { serialize } from "object-to-formdata";

export const loginUser = (user: ILogin) =>
  http<IUserWithToken>(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export const registerUser = async (user: IRegister) =>
  await http<IUserWithToken>(REGISTER_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export const resetUserPassword = async (user: IResetPassword) =>
  await http(PASSWORD_RESET, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export const changeUserPassword = async (user: IChangePassword) =>
  await http(PASSWORD_RESET, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });

export const logoutUser = () =>
  http(LOGOUT_URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const getUser = (id: string) =>
  http<{ data: IUser }>(USER(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

interface IUserProfilePayload {
  first_name: string;
  last_name: string;
  town: string;
  phone_number: string;
  skills: string;
  certificates: string[];
  avatar?: Blob;
}

export const changeProfile = async (profile: IUserProfilePayload) =>
  await http<IUser>(PROFILE, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ profile }),
  });
