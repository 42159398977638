import React, { useEffect, useState } from "react";
import { ITask } from "types/task";
import { Box, Flex, Modal } from "components";
import { Accordion } from "components/Accordion";
import { TechBox } from "components/_post";
import { Button, Select } from "components/_form";
import { IOption } from "components/_form/Select/Select";
import { AddTaskModal } from "../components/AddTaskModal";
import MDEditor from "@uiw/react-md-editor";
import { deleteTask, getTasks } from "services/task";
import { useFetchDictionaryTechnologies } from "hooks/useFetchDictionaryTechnologies";
import { P } from "components/Typography/Typography";

export const TasksView = () => {
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [taskId, setTaskId] = useState<number | undefined>();
  const [lang, setLang] = useState("");
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { dictionaryTechnologies } = useFetchDictionaryTechnologies();

  const onLanguageChange = (val: IOption) => {
    if (!val) {
      setLang("");
      return;
    }
    setLang(val.value);
  };

  useEffect(() => {
    getTasks().then((res) => {
      setTasks(res.data);
    });
  }, []);

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Box width="200px">
          <Select
            label="Język"
            isClearable
            isMulti={false}
            options={dictionaryTechnologies || []}
            onChange={onLanguageChange}
          />
        </Box>
        <Button onClick={() => setIsTaskModalOpen(true)}>Dodaj zadanie</Button>
      </Flex>
      <Flex flexDirection="column" flexWrap="wrap">
        {tasks
          ?.filter((task) =>
            lang ? task.technology.id.toString() === lang : true
          )
          .map((task) => (
            <Flex m={2} key={task.id}>
              <Accordion
                title={task.name}
                customElement={<TechBox>{task.technology.name}</TechBox>}
              >
                <Box>
                  <div data-color-mode="light">
                    <MDEditor.Markdown
                      style={{ padding: 10 }}
                      source={task.description}
                      linkTarget="_blank"
                    />
                  </div>
                  <Flex justifyContent="end" m={2}>
                    <Button
                      type="button"
                      onClick={() => {
                        setTaskId(task.id);
                        setIsTaskModalOpen(true);
                      }}
                    >
                      Edytuj
                    </Button>
                    <Box ml={2}>
                      <Button
                        type="button"
                        onClick={() => {
                          setTaskId(task.id);
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        Usuń
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </Accordion>
            </Flex>
          ))}
      </Flex>
      <AddTaskModal
        key={+isTaskModalOpen}
        taskId={taskId}
        isOpen={isTaskModalOpen}
        onCancelClick={() => {
          setIsTaskModalOpen(false);
          setTaskId(undefined);
        }}
        onSubmitSuccess={(newTask) => {
          setIsTaskModalOpen(false);
          newTask &&
            setTasks((prevTasks) => {
              if (prevTasks.find(({ id }) => id === newTask.id)) {
                return [
                  newTask,
                  ...prevTasks.filter(({ id }) => id !== newTask.id),
                ];
              } else {
                return [newTask, ...prevTasks];
              }
            });
          setTaskId(undefined);
        }}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onCancelClick={() => setIsDeleteModalOpen(false)}
      >
        <Box>
          <P variant="h3" fontWeight={400}>
            Czy napewno chcesz usunąć swoje zadanie?
          </P>
          <Flex justifyContent="end" mt={3}>
            <Box mr={2}>
              <Button
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setTaskId(undefined);
                }}
              >
                Nie
              </Button>
            </Box>
            <Button
              onClick={() => {
                if (taskId) {
                  deleteTask(taskId);
                  setTasks((prevTasks) => [
                    ...prevTasks.filter(({ id }) => id !== taskId),
                  ]);
                  setIsDeleteModalOpen(false);
                  setTaskId(undefined);
                }
              }}
            >
              Tak
            </Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};
