import { ToastContainer } from "react-toastify";
import { useRoutes } from "react-router-dom";
import { routes } from "routes";

export const App = () => {
  const appRoutes = useRoutes(routes);

  return (
    <div>
      {appRoutes}
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
