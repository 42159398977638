export const sidebarItems = [
  {
    title: "Dodaj ogłoszenie",
    path: "/app/add-post",
    private: true,
    type: "admin",
  },
  {
    title: "Moje zadania",
    path: "/app/my-tasks",
    private: true,
    type: "admin",
  },
  {
    title: "Ogłoszenia",
    path: "/app/offers",
    private: true,
    type: "admin",
  },
  //
  {
    title: "Dodaj ogłoszenie",
    path: "/app/add-post",
    private: true,
    type: "company",
  },
  {
    title: "Moje zadania",
    path: "/app/my-tasks",
    private: true,
    type: "company",
  },
  {
    title: "Moje ogłoszenia",
    path: "/app/my-offers",
    private: true,
    type: "company",
  },
  {
    title: "Aktywne rekrutacje",
    path: "/app/active-offers",
    private: false,
    type: "candidate",
  },
  {
    title: "Twoje aplikacje",
    path: "/app/your-applies",
    private: true,
    type: "candidate",
  },
  {
    title: "Edytor kodu",
    path: "/app/code-edit",
    private: false,
    type: "candidate",
  },
];
