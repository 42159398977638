import React, { useState } from "react";
import { Flex, Box, Avatar, Dropdown, Menu } from "components";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { Button } from "components/_form";
import {
  useAuthDispatchContext,
  useAuthStateContext,
} from "contexts/AuthContext";
import { useNavigate } from "react-router";
import { logoutUser } from "services/auth";
import { device } from "config/theme";
import { P } from "components/Typography/Typography";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "routes";
import { API_PHOTO_URL } from "constants/paths";
import { useFetchPages } from "hooks/useFetchPages";
import Logo from "assets/img/logo_dados.png";

export interface INavbar {
  hideLogo?: boolean;
  logged: boolean;
  onMenuIconClick: () => void;
  isSidebarOpen: boolean;
}

const Root = styled(Flex)`
  grid-area: navbar;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
  `}
`;

const Wrapper = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    width: 100%;
    padding: 14px;
  `}
`;

const MenuToggleIcon = styled(MenuIcon)`
  display: none;
  margin-right: auto;
  padding: 10px;
  height: 50px;
  width: 50px;
  cursor: pointer;

  @media ${device.tablet} {
    display: block;
  }
`;

const Breadcrumbs = styled.ul`
  margin: 0;
  align-items: center;
  height: 100%;
  list-style: none;
  color: rgba(19, 19, 21, 0.65);
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 18px;
  display: flex;
`;

const Image = styled.img`
  padding-left: 6px;
  height: 70px;
  cursor: pointer;
  
  @media ${device.tablet} {
    display: none;
  }
`;

const TabletBox = styled(Box)`
  display: block;

  @media ${device.tablet} {
    display: none;
  }
`;

const TabletFlex = styled(Flex)`
  display: block;

  @media ${device.tablet} {
    display: none;
  }
`;

export const Navbar: React.FC<INavbar> = ({
  logged,
  onMenuIconClick,
  hideLogo = false,
  isSidebarOpen,
}) => {
  const breadcrumbs = useBreadcrumbs(routes);

  const navigate = useNavigate();
  const { setToken, setUserProfile } = useAuthDispatchContext();
  const { userProfile } = useAuthStateContext();
  const [isLoading, setIsLoading] = useState(false);

  const pages = useFetchPages();

  const logout = async () => {
    setIsLoading(true);
    setToken(null);
    setUserProfile(null);

    window.localStorage.clear();
    navigate("/");
    try {
      await logoutUser();
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const profileDropdownItems = [
    {
      label: "Profil",
      onClick: () => navigate("/app/user-details/" + userProfile?.id),
    },
    { label: "Wyloguj", onClick: logout },
  ];

  return (
    <Root>
      {!hideLogo && <Image src={Logo}/>}
      <Wrapper alignItems="center" justifyContent="flex-end">
        <MenuToggleIcon onClick={onMenuIconClick} />
        <TabletFlex width="100%" justifyContent="flex-start">
          <Breadcrumbs>
            {breadcrumbs.map(({ breadcrumb }, index) => [
              index ? (
                <React.Fragment key={index}>&nbsp;{"/"}&nbsp;</React.Fragment>
              ) : (
                ""
              ),
              breadcrumb,
            ])}
          </Breadcrumbs>
        </TabletFlex>

        <TabletBox mr={4}>
          <Menu
            view="vertical"
            onMenuIconClick={onMenuIconClick}
            items={[...(pages || [])]}
          />
        </TabletBox>

        {logged ? (
          <Box mr={10}>
            <Dropdown items={profileDropdownItems}>
              <Avatar
                border
                url={`${API_PHOTO_URL}${userProfile?.profile?.avatar}`}
              />
              <P variant="body" mx={2} color="secondary">
                {userProfile?.email}
              </P>
            </Dropdown>
          </Box>
        ) : (
          <Flex justifyContent="flex-end">
            <Box mr={3}>
              <Button
                variant="blue"
                type="submit"
                onClick={() => navigate("/signup")}
              >
                Zarejestruj
              </Button>
            </Box>
            <Box mr={3}>
              <Button
                variant="lightBlue"
                type="submit"
                onClick={() => navigate("/")}
              >
                Zaloguj
              </Button>
            </Box>
          </Flex>
        )}
      </Wrapper>
    </Root>
  );
};
