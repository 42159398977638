import { Flex } from "components";
import { Box } from "components/Box";
import { device } from "config/theme";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

export const ResponsiveBox = styled(Box)`
  width: 50%;
  padding: 50px;

  @media ${device.tablet} {
    width: 300%;
    padding: 20px 0;
  }
`;

export const ColouredBox = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    border-radius: 12px;
    padding: 50px;
    min-height: 200px;
    width: 50%;

    @media ${device.tablet} {
      width: 300%;
      padding: 30px;
    }
  `}
`;
