import React, { useCallback, useEffect, useState } from "react";
import { Flex, Card, Box } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStateContext } from "contexts/AuthContext";
import { H4, P } from "components/Typography/Typography";
import { IPost, IUserPostStatus } from "types/post";
import { deletePost, getPost, getPostStatuses } from "services/post";
import { PostDetails } from "../../components/_post/PostDetails";
import { PostCandidateList } from "components/_post/PostCandidateList";
import { MainInfo } from "components/_post/MainInfo";
import { Button } from "components/_form";
import { toast } from "react-toastify";

export interface IPostView {
  horizontal?: boolean;
  waiting?: boolean;
  pending?: boolean;
}

export const PostView = ({ horizontal = true }: IPostView): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [userPostStatuses, setUserPostStatuses] = useState<IUserPostStatus[]>();

  const { id } = useParams<{ id: string }>();
  const parsedId = parseInt(id as string, 10);

  const [responseData, setResponseData] = useState<IPost["data"] | null>(null);

  const navigate = useNavigate();

  const { userProfile } = useAuthStateContext();

  const fetchPost = useCallback(
    async (id: number) => {
      setError("");
      setIsLoading(true);
      try {
        const response = await getPost(id, !userProfile);
        if (!response) return;
        setResponseData(response.data);
      } catch (err: any) {
        setError(err.error);
      } finally {
        setIsLoading(false);
      }
    },
    [id]
  );

  const fetchUserPostStatuses = useCallback(async (id: number) => {
    const { data } = await getPostStatuses({ post_id: id });

    setUserPostStatuses(data);
  }, []);

  const handleDeletePost = async () => {
    if (id) {
      try {
        const res = await deletePost(id);
        navigate(`/app`);
        toast.success("Pomyślnie usunięto ogłoszenie.");
      } catch (e) {
        console.log("err", e);
        toast.error("Nie udało się usunąć ogłoszenia.");
      }
    }
  };

  useEffect(() => {
    fetchPost(parsedId);
  }, []);

  useEffect(() => {
    userProfile?.id === responseData?.user?.id &&
      fetchUserPostStatuses(parsedId);
  }, [responseData?.user?.id]);

  return isLoading ? (
    <H4 variant="h3" fontWeight={600}>
      Loading...
    </H4>
  ) : (
    <Card>
      <Flex flexDirection="column">
        {responseData && (
          <>
            <MainInfo responseData={responseData} />

            <PostDetails responseData={responseData} />

            {(userProfile?.id === responseData?.user?.id || userProfile?.role === 'admin') && (
              <>
                <Flex marginLeft="auto">
                  <Box mr={2}>
                    <Button
                      type="button"
                      onClick={() => {
                        navigate(`/app/add-post/${id}`);
                      }}
                    >
                      Edytuj
                    </Button>
                  </Box>
                  <Button type="button" onClick={handleDeletePost}>
                    Usuń
                  </Button>
                </Flex>

                {userPostStatuses && (
                  <PostCandidateList
                    userPostStatuses={userPostStatuses}
                    postId={parsedId}
                  />
                )}
              </>
            )}
          </>
        )}
      </Flex>
    </Card>
  );
};
