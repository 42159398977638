import { FC, useEffect, useState } from "react";
import { getPosts } from "services/post";
import { IPosts } from "types/post";
import { Flex, PostCard } from "components";
import { useAuthStateContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Filters } from "components/Filters";
import {IFiltersData} from "../components/Filters/Filters";
import { Button } from "components/_form";
import { P } from "components/Typography/Typography";
import { ReactComponent as ScrollIcon } from "assets/icons/scroll.svg";

interface IOffersView {
  filter?: "available";
}

export const PostsView: FC<IOffersView> = ({ filter }) => {
  const [posts, setPosts] = useState<IPosts | null>(null);
  const [filters, setFilters] = useState<Partial<IFiltersData | { job_title: string }>>()
  const [page, setPage] = useState(1);
  const [showMoreButton, setShowMoreButton] = useState(true)

  const { token } = useAuthStateContext();
  const navigate = useNavigate();

  const handleGetPosts = (customPage?: number, filters?: Object) => {
    let queryFilters = {
      filter_scope: filter,
      page: customPage || page,
      // per_page: 10
    };

    if (filters) {
      queryFilters = {...queryFilters, ...filters}
    }

    getPosts(queryFilters, !token).then((res) => {
      setShowMoreButton(!!res.pagination?.next)
      setPosts((prevPosts) => ({
        ...res,
        data: [...(prevPosts?.data || []), ...res.data],
      }))
    });
  };

  const handleLoadMorePosts = async () => {
    setPage((prevState) => prevState + 1);
    handleGetPosts(page + 1, filters);
  };

  useEffect(() => {
    setPage(1)
    setPosts(null)
    handleGetPosts(page, filters)
  }, [filters]);

  return (
    <Flex flexDirection={["column-reverse", "row"]}>
      <Flex width={["100%", "70%"]} flexDirection="column">
        {posts &&
          posts.data.map((el) => (
            <PostCard
              key={el.id}
              post={el}
              button={{
                text: "Szczegóły",
                onClick: () => navigate(`/app/active-offers/${el.id}`),
              }}
            />
          ))}

        {showMoreButton ? (
          <Flex justifyContent="center" width="100%">
            <Button variant="secondary" onClick={handleLoadMorePosts}>
              <ScrollIcon />
              <P variant="body" color="secondary">
                Więcej ofert
              </P>
            </Button>
          </Flex>
        ) : (
          ""
        )}
      </Flex>
      <Flex mx={3} mb={3} width={["90%", "27%"]}>
        <Filters
          onSubmitFilter={(val => {
            setPage(1)
            setFilters(val)
          })}
        />
      </Flex>
    </Flex>
  );
};
