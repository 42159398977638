import React, { useCallback, useEffect, useState } from "react";
import { Flex, Card, Box } from "components";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "components/Modal";
import { IUserPostStatus } from "types/post";
import { useAuthStateContext } from "contexts/AuthContext";
import { Button } from "../components/_form";
import {
  deletePostStatus,
  getPostStatus,
  updatePostStatus,
} from "../services/post";
import { H2, P } from "components/Typography/Typography";
import { MainInfo } from "components/_post/MainInfo";
import { ColouredBox } from "components/ColouredBox";
import { API_PHOTO_URL } from "constants/paths";
import { Accordion } from "components/Accordion";
import { TechBox } from "components/_post";
import MDEditor from "@uiw/react-md-editor";
import { CodePlace } from "components/CodeEditor/CodeEditor";
import { postStatuses } from "constants/post";

export interface IPostView {
  horizontal?: boolean;
  waiting?: boolean;
  pending?: boolean;
}

export const ApplyDetailsView = ({
  horizontal = true,
}: IPostView): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const parsedId = parseInt(id as string, 10);

  const [error, setError] = useState("");
  const [responseData, setResponseData] = useState<IUserPostStatus | null>(
    null
  );

  const { userProfile } = useAuthStateContext();

  const fetchPost = useCallback(
    async (id: number) => {
      if (userProfile) {
        setError("");
        setIsLoading(true);
        try {
          const response = await getPostStatus({
            post_id: id,
            user_id: userProfile.id,
          });
          if (!response) return;
          setResponseData(response.data);
        } catch (err: any) {
          setError(err.error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [id]
  );

  const handleDeleteApplication = () => {
    if (userProfile?.id && parsedId) {
      deletePostStatus({ user_id: userProfile?.id, post_id: parsedId });

      navigate(`../../../app/your-applies`);
    }
  };

  const handleSendApplication = () => {
    if (userProfile?.id && parsedId) {
      updatePostStatus(
        { user_id: userProfile?.id, post_id: parsedId },
        { user_post_status: { status: "sent" } }
      );

      navigate(`../../../app/your-applies`);
    }
  };

  useEffect(() => {
    fetchPost(parsedId);
  }, []);

  return (
    <>
      <Card>
        <H2 variant="h2" textAlign="center" mb={3}>
          Twoja aplikacja
        </H2>
        <ColouredBox p={3} mb={3}>
          <H2 variant="h2" textAlign="center">
            Status: {responseData && postStatuses[responseData.status]}
          </H2>
        </ColouredBox>
        <Flex flexDirection="column">
          {responseData?.post && <MainInfo responseData={responseData.post} />}

          <P variant="body" mt={3}>
            Załączone CV
          </P>

          <Flex justifyContent="end" mt={1}>
            <Button fullWidth variant="lightBlue">
              <a rel="noreferrer" target="_blank" href={`${API_PHOTO_URL}${responseData?.cv}`}>CV</a>
            </Button>
          </Flex>

          <H2 variant="h2" fontWeight={500} mt={3}>
            Rozwiązanie zadania
          </H2>

          {responseData?.user_solutions[0]?.post.tasks.map((task) => (
            <Flex key={task.id} m={2}>
              <Accordion
                title={task.name}
                customElement={<TechBox>{task.technology.name}</TechBox>}
              >
                <Box>
                  <div data-color-mode="light">
                    <MDEditor.Markdown
                      style={{ padding: 10 }}
                      source={task.description}
                      linkTarget="_blank"
                    />
                  </div>
                </Box>

                {responseData.user_solutions.map(
                  ({ task_id, solution }) =>
                    task_id === task.id && (
                      <CodePlace
                        key={id}
                        theme="dracula"
                        language={task.technology.name.toLowerCase()}
                        displayName={task.technology.name}
                        value={solution}
                      />
                    )
                )}
              </Accordion>
            </Flex>
          ))}

          <Flex justifyContent="end" mt={3}>
            <Button variant="lightBlue" onClick={() => setIsModalOpen(true)}>
              Usuń swoją aplikacje
            </Button>
            {responseData?.status === "draft" && (
              <Box ml={2}>
                <Button
                  variant="lightBlue"
                  onClick={() => setIsSubmitModalOpen(true)}
                >
                  Wyślij swoją aplikacje
                </Button>
              </Box>
            )}
          </Flex>
        </Flex>
      </Card>
      <Modal isOpen={isModalOpen} onCancelClick={() => setIsModalOpen(false)}>
        <Box>
          <P variant="h3">Czy napewno chcesz usunąć swoją aplikacje?</P>
          <Flex justifyContent="end" mt={3}>
            <Box mr={2}>
              <Button onClick={() => setIsModalOpen(false)}>Nie</Button>
            </Box>
            <Button onClick={handleDeleteApplication}>Tak</Button>
          </Flex>
        </Box>
      </Modal>

      <Modal
        isOpen={isSubmitModalOpen}
        onCancelClick={() => setIsSubmitModalOpen(false)}
      >
        <Box>
          <P variant="h3">Czy napewno chcesz Wysłać swoją aplikacje?</P>
          <Flex justifyContent="end" mt={3}>
            <Box mr={2}>
              <Button onClick={() => setIsSubmitModalOpen(false)}>Nie</Button>
            </Box>
            <Button onClick={handleSendApplication}>Tak</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};
