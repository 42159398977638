import { Box } from "components";
import { H2, H3, P } from "components/Typography/Typography";
import { Button, Checkbox, TextareaField, TextField } from "components/_form";
import { theme } from "config/theme";
import { Controller, useForm } from "react-hook-form";
import { ColouredBox, ResponsiveBox, Wrapper } from "./ContactView.styled";
import { Map } from "components/Map";

export const ContactView = () => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      nameAndSurname: "",
      email: "",
      message: "",
      agreement: false,
    },
  });

  return (
    <Wrapper pt={15} justifyContent="center">
      <ColouredBox>
        <H3 mb={4} variant="h3">
          ROBERT DADOS CONSULTING
        </H3>
        <P variant="body" fontWeight={600}>
          ul. Kołobrzeska 42C, lok. 5
        </P>
        <P variant="body" fontWeight={600}>
          80-394 Gdańsk
        </P>
        <P variant="body" fontWeight={600}>
          NIP 5842561049
        </P>
        <P variant="body" fontWeight={600} pt={3}>
          e-mail: robert.dados@gmail.com
        </P>
        <P variant="body" fontWeight={600}>
          tel.: 518 793 893
        </P>
      </ColouredBox>
      <ResponsiveBox>
        <H2 mb={3} variant="h2">
          Kontakt
        </H2>
        <form>
          <Box mb={3}>
            <TextField
              bgColor={theme.palette.neutral.white}
              label="Imię i nazwisko"
              {...register("nameAndSurname")}
            />
          </Box>
          <Box mb={3}>
            <TextField
              bgColor={theme.palette.neutral.white}
              label="Twój e-mail"
              {...register("email")}
            />
          </Box>
          <Box mb={3}>
            <TextareaField
              bgColor={theme.palette.neutral.white}
              label="Treść wiadomości"
              {...register("message")}
            />
          </Box>
          <Box display="flex" justifyContent="center" mb={4} alignItems="start">
            <Controller
              control={control}
              name="agreement"
              render={({ field }) => <Checkbox onChange={field.onChange} />}
            />
            Wyrazam zgode na przetwarzanie moich danych osobowych podanych
            formularzu w celu uzyskania od powiedzi na zapytanie. Moja zgoda
            jest dobrowolna, a jednoczesnie niezbedna do uzyskania od powiedzi.
          </Box>

          <Button variant="blue" type="submit">
            Wyślij
          </Button>
        </form>
      </ResponsiveBox>

      {/* <Map lat={54.404485} lng={18.596307} /> */}
    </Wrapper>
  );
};
