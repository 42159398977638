import styled from "styled-components";
import { device } from "config/theme";

export const Wrapper = styled.main`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.secondary};
  `}
  display: grid;
  height: 100vh;
  grid-template-columns: 1.1fr 5fr;
  grid-template-rows: 1fr 12fr;
  grid-template-areas:
    "sidebar navbar"
    "sidebar content";

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "navbar"
      "content";
  }
`;
export const Content = styled.div`
  overflow: scroll;
  padding: 32px;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.light};
  `};
  grid-area: content;

  @media screen and (max-width: 40em) {
    padding: 32px 0 0 0;
  }
`;
