import styled from "styled-components";

import { Card, Box, Flex } from "components";
import { ApplicationStatus, IPost } from "types/post";
import { FC } from "react";
import { Button } from "components/_form";
import { H2, P } from "components/Typography/Typography";
import { postStatuses } from "constants/post";
import { TechBox } from "components/_post";
import { device } from "config/theme";
import { API_PHOTO_URL } from "constants/paths";
import { ReactComponent as LocalisationIcon } from "assets/icons/localisation.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";

interface IPostCard {
  post: IPost["data"];
  status?: ApplicationStatus;
  button: {
    text: string;
    onClick: () => any;
  };
}

export const Wrapper = styled(Card)`
  padding: unset;
  margin-bottom: 20px;

  @media ${device.laptop} {
    width: 48%;
  }
  @media ${device.tablet} {
    width: 90%;
    margin: 0 auto 30px;
  }
`;
const StatusArea = styled(H2).attrs({ variant: "h2" })`
  padding: 14px;
  text-align: center;
  ${({ theme }) => `
    color: ${theme.palette.neutral.dark}};
    background-color: ${theme.palette.neutral.lightBlue}};
  `}
`;
const Logo = styled.img`
  height: 100%;
`;

const Title = styled(H2)`
  max-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PostCard: FC<IPostCard> = ({ post, status, button }) => {
  return (
    <Wrapper onClick={button.onClick}>
      {status && <StatusArea>{postStatuses[status]}</StatusArea>}
      <Flex height="140px">
        <Logo
          src={
            post.photo
              ? `${API_PHOTO_URL}${post.photo}`
              : "https://via.placeholder.com/140x140"
          }
        />
        <Flex
          width="100%"
          p={[1, 3]}
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex flexDirection="column">
            <Title mb={2} variant="h2">
              {post.name}
            </Title>

            <Title mb={2} variant="h4">
              {post.job_title}
            </Title>

            <Flex>
              <Box mr={1}>
                <LocalisationIcon />
              </Box>
              <P mb={2} variant="body" color="secondary" fontWeight={700}>
                {post.address}
              </P>
            </Flex>

            <Flex>
              {post.technologies?.map((tech) => (
                <TechBox mb={3} key={tech.id}>
                  {tech.name}
                </TechBox>
              ))}
            </Flex>
          </Flex>
          <Flex flexDirection="column" alignItems="end">
            <P mb={2} variant="h3" color="secondary">
              {post?.max_range_salary &&
                post?.min_range_salary + " - " + post?.max_range_salary}{" "}
              pln
            </P>
            <Flex mb={3} mt={[0, 4]}>
              <P mb={2} variant="body" color="secondary">
                {post.start_at && post.start_at.split(" ")[0]} -&nbsp;
              </P>
              <P mb={2} variant="body" color="secondary">
                {post.finish_at && post.finish_at.split(" ")[0]}
              </P>
              <Box ml={2}>
                <CalendarIcon />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
