import { TASK, TASKS } from "constants/paths";
import qs from "qs";
import { ICreateTask } from "types/forms/task";
import { ITask, ITasks } from "types/task";
import { http } from "utilities/http";

export const getTasks = async (filters?: {
  filter?: "company_tasks" | "all_tasks" | "default_tasks";
  page?: string | number;
  per_page?: string | number;
}) => {
  const query = qs.stringify(filters);

  return await http<ITasks>(`${TASKS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getTask = async (id: number) => {
  return await http<{ data: ITask }>(TASK(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createTask = (task: ICreateTask) =>
  http<{ data: ITask }>(TASKS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(task),
  });

export const updateTask = (
  id: number,
  task: { task: Omit<ICreateTask["task"], "user_id"> }
) =>
  http<{ data: ITask }>(TASK(id), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(task),
  });

export const deleteTask = async (id: number) => {
  return await http<{ data: ITask }>(TASK(id), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
