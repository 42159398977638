import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Accordion } from "components/Accordion";
import { P } from "components/Typography/Typography";
import {Button, Radio, Select, TextField} from "components/_form";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { theme } from "config/theme";
import ReactDatePicker from "react-datepicker";
import { useState } from "react";
import {formatDateConstructable} from "../../utilities/convertDate";
import {useFetchDictionaryTechnologies} from "../../hooks/useFetchDictionaryTechnologies";

const StyledAccordionChildren = styled(Flex)`
  padding: 10px;
  border-radius: 0px 0px 6px 6px;
  ${({ theme }) => `
      background-color: ${theme.palette.neutral.white};
  `}
`;

const selectStyles = {
  dropdownIndicator: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: state.isFocused ? "white" : "",
    borderColor: state.isFocused ? theme.palette.neutral.mainBlue : "white",
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderRadius: state.isFocused ? "6px 6px 0 0" : "6px",
    border: 0,
    backgroundColor: state.isFocused ? theme.palette.neutral.mainBlue : "white",
    color: state.isFocused ? "white" : "",
    direction: "rtl",
    padding: "3px 7px 3px 7px",
    boxShadow: "none",
  }),
  placeholder: (styles: any, state: any) => ({
    ...styles,
    color: state.isFocused ? "white" : "",
    marginLeft: 0,
    position: "absolute",
    left: 0,
  }),
  input: (styles: any, state: any) => ({
    ...styles,
    color: state.isFocused ? "white" : "",
  }),
  singleValue: (styles: any, state: any) => ({
    ...styles,
    color: state.isFocused ? "white" : "",
  }),
};

export interface IFiltersData {
  filter_scope?: string
  min_range_salary?: string
  max_range_salary?: string
  finish_at?: string
  address?: string
  // address?: IOption
  technology_ids?: number[]
}

interface IFilter {
  onSubmitFilter: (val: IFiltersData & { job_title: string }) => void;
}

export const Filters: React.FC<IFilter> = ({
                                             onSubmitFilter,
                                           }) => {
  const [job_title, setJobTitle] = useState('');
  const [finishDate, setFinishDate] = useState(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const { dictionaryTechnologies } = useFetchDictionaryTechnologies();
  const [filters, setFilters] = useState<IFiltersData>({
    // filter_scope: "available",
    min_range_salary: "",
    max_range_salary: "",
    finish_at: "",
    address: "",
    technology_ids: [],
  })

  const submitFilters = () => {
    const preparedFilters = {...filters, job_title}
    Object.keys(preparedFilters).forEach((key) => {
      if (preparedFilters.hasOwnProperty(key) &&
        // @ts-ignore
        (!preparedFilters[key] || preparedFilters[key].length < 1)) delete preparedFilters[key]
    });
    onSubmitFilter(preparedFilters)
  }

  return (
    <Flex width="100%" flexWrap="wrap" flexDirection="column">
      <Flex mb={3} width="100%">
        <TextField
          value={job_title}
          onChange={(e) => setJobTitle(e.target.value)}
          height="40px"
          placeholder="Stanowisko"
          iconSize={93}
          bgColor="white"
          onIconClick={submitFilters}
          iconBackgroundColor={theme.palette.primary.secondary}
          icon={
            <>
              <Box mr={2}>
                <SearchIcon />
              </Box>
              Szukaj
            </>
          }
        />
      </Flex>
      <Flex m={1} flexDirection="column">
        <div></div>
        <P variant="h3" mb={3}>
          Filtry:
        </P>
        <Flex flexDirection="column">
          <Accordion title="Wynagrodzenie">
            <StyledAccordionChildren flexDirection="column">
              <Flex justifyContent="center" alignItems="center" mb={3}>
                <P variant="body">od: </P>
                <Box ml={2}>
                  <TextField
                    bgColor="white"
                    height="32px"
                    unit="PLN"
                    value={filters.min_range_salary}
                    onChange={(e) => {
                      !isNaN(+e.target.value)
                      && setFilters(prevState => {
                        return {...prevState, min_range_salary: e.target.value}
                      })
                    }}
                  />
                </Box>
              </Flex>
              <Flex justifyContent="center" alignItems="center">
                <P variant="body">do: </P>
                <Box ml={2}>
                  <TextField
                    bgColor="white"
                    height="32px"
                    unit="PLN"
                    value={filters.max_range_salary}
                    onChange={(e) => {
                      !isNaN(+e.target.value)
                      && setFilters(prevState => {
                        return {...prevState, max_range_salary: e.target.value}
                      })
                    }}
                  />
                </Box>
              </Flex>
            </StyledAccordionChildren>
          </Accordion>
          <Box my={3}>
            <TextField
              placeholder="Kraj"
              bgColor="white"
              height="32px"
              value={filters.address}
              onChange={(e) => {
                setFilters(prevState => {
                  return {...prevState, address: e.target.value}
                })
              }}
            />
            {/*<Select*/}
            {/*  placeholder="Kraj"*/}
            {/*  options={[{ value: "Polska", label: "Polska" }]}*/}
            {/*  selectedOptions={filters.address && [filters.address]}*/}
            {/*  onChange={(val) => !Array.isArray(val) && setFilters(prevState => {*/}
            {/*    return {...prevState, address: val}*/}
            {/*  })}*/}
            {/*  selectStyles={selectStyles}*/}
            {/*/>*/}
          </Box>
          <Box mb={3}>
            <Accordion
              title={filters.finish_at
                ? `Data zakończenia rekrutacji - ${filters.finish_at}`
                : "Data zakończenia rekrutacji"}
              customOpen={isDatePickerOpen}
              customSetOpen={setIsDatePickerOpen}
            >
              <ReactDatePicker
                value={filters.finish_at}
                selected={finishDate}
                onChange={(date: Date) => {
                  setFilters(prevState => {
                    return {...prevState, finish_at: formatDateConstructable(date)}
                  })
                  setFinishDate(date);
                  setIsDatePickerOpen((prevState) => !prevState);
                }}
                inline
              />
            </Accordion>
          </Box>

          <Select
            placeholder="Technologie"
            options={dictionaryTechnologies || []}
            selectStyles={selectStyles}
            isMulti
            onChange={(val) => Array.isArray(val) && setFilters(prevState => {
              return {...prevState, technology_ids: val.map(el => +el.value)}
            })}
          />
          {/*<Flex justifyContent="space-around" alignItems="center" my={2}>*/}
          {/*  <Radio*/}
          {/*    label="dostępne"*/}
          {/*    name="available"*/}
          {/*    value="available"*/}
          {/*    checked={filters.filter_scope === "available"}*/}
          {/*    onClick={() => setFilters(prevState => {*/}
          {/*      return {...prevState, filter_scope: "available"}*/}
          {/*    })}*/}
          {/*  />*/}
          {/*  <Radio*/}
          {/*    label="niedostępne"*/}
          {/*    name="available"*/}
          {/*    checked={filters.filter_scope !== "available"}*/}
          {/*    value="0"*/}
          {/*    onClick={() => setFilters(prevState => {*/}
          {/*      return {...prevState, filter_scope: ''}*/}
          {/*    })}*/}
          {/*  />*/}
          {/*</Flex>*/}
          {/*<Select*/}
          {/*  placeholder="Języki"*/}
          {/*  options={[{ value: "Polsk", label: "Polski" }]}*/}
          {/*  selectStyles={selectStyles}*/}
          {/*/>*/}
          {/*<Select*/}
          {/*  placeholder="Lokalizacja"*/}
          {/*  options={[{ value: "Warszawa", label: "Warszawa" }]}*/}
          {/*  selectStyles={selectStyles}*/}
          {/*/>*/}
        </Flex>

        <Flex mt={3}>
          <Button variant="blue" onClick={submitFilters}>Zastosuj filtry</Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
