import { LOGIN_URL } from "../constants/paths";

export const http = async <T = null>(
  url: string,
  options?: RequestInit
): Promise<T> => {
  const token = localStorage.getItem("token");
  if (token && options && url !== LOGIN_URL) {
    options.headers = { ...options?.headers, Authorization: `Bearer ${token}` };
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    const error = await response.json();
    throw error;
  }

  if (response.status === 204 || response.statusText === "No Content") {
    return new Promise((resolve) => {
      resolve(null as any);
    });
  }

  return response.json();
};
