import React, {useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup";

import {ReactComponent as PasswordIcon} from "assets/icons/lock-password-fill.svg";
import {useForm} from "react-hook-form";
import {changePasswordSchema} from "schemas/auth";
import {ErrorText} from "components/_form/ErrorText";
import {Box, Flex, Typography, Spinner} from "components";
import {Button, TextField} from "components/_form";
import {useNavigate, useParams} from "react-router";
import {changeUserPassword} from "services/auth";

interface IRegisterFormData {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
}

export const ChangePasswordView = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: {errors},
  } = useForm<IRegisterFormData>({
    mode: "onChange",
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmit = async (data: IRegisterFormData) => {
    setError("");
    setIsLoading(true);

    if (!token) return;
    const dataToFetch = {...data, reset_password_token: token};

    try {
      await changeUserPassword(dataToFetch);
      setIsLoading(false);
      navigate("/");
    } catch (err: any) {
      setError(err.error);
      setIsLoading(false);
    }
  };

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography.H2
        variant="h2"
        color="primary"
        textAlign="center"
        fontWeight={600}
        mb={4}
      >
        Change password
      </Typography.H2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type="password"
          label="Password"
          transparent
          icon={<PasswordIcon/>}
          {...register("password")}
          error={errors.password?.message}
        />
        <Box mt={4} mb={2}>
          <TextField
            type="password"
            label="Password again"
            transparent
            icon={<PasswordIcon/>}
            {...register("password_confirmation")}
            error={errors.password_confirmation?.message}
          />
        </Box>

        {error && <ErrorText>{error}</ErrorText>}

        <Box my={4}>
          {isLoading
            ? <Spinner/>
            : <Button
              variant="blue"
              fullWidth
              type="submit"
              disabled={!formState.isValid}
            >
              Resetuj
            </Button>
          }
        </Box>
      </form>
    </Flex>
  );
};
