import {StyledCard, StyledFlex, StyledP} from "./CandidateAccordion.styled";
import {Flex} from "components/Flex";
import {ReactNode, useEffect, useState} from "react";
import {Box} from "components/Box";
import {ReactComponent as AccordionIcon} from "assets/icons/arrow-down.svg";
import {useNavigate} from "react-router-dom";
import {H3, P} from "components/Typography/Typography";
import {Button} from "components/_form";
import {Avatar} from "components/Avatar";
import {ApplicationStatus, IPost, IUserPostStatus} from "types/post";
import {Pie} from "components/Pie";
import {getDetailedEvaluations, updatePostStatus} from "services/post";
import {translation} from "constants/translation";
import {toast} from "react-toastify";
import {API_PHOTO_URL} from "constants/paths";
import {IDetailedEvaluations, IDetailedEvaluationsData} from "../../types/task";
import React from "react";

interface AccordionProps {
  children?: ReactNode;
  candidate: IUserPostStatus["user"];
  userSolutions: { post: IPost["data"]; task_id: number; solution: string, id?: string | number }[];
  isAccordionOpen?: boolean;
  OnOpenClick?: (arg?: number) => void;
  postId?: number;
  status?: string;
  cv?: string;
}

const scoreData = {
  "algorithmic_thinking": "Myślenie algorytmiczne",
  "algorithmic_thinking.run_time": "czas wykonaia skryptu (s)",
  "algorithmic_thinking.code_length": "liczba linii kodu",
  "algorithmic_thinking.is_correct_output": "czy wyjście jest poprawne",
  //
  "correct_structure": "Poprawna struktura",
  "correct_structure.function_number": "liczba funkcji",
  "correct_structure.class_number": "liczba klas",
  //
  "correct_naming": "Poprawne nazewnictwo",
  "correct_naming.function_name_errors": "błędy w nazwach funkcji:",
  "correct_naming.function_name_errors.code": "kod błędu i opis ze style guide'u języka",
  "correct_naming.function_name_errors.function_name": "nazwa funkcji z błędem",
  "correct_naming.function_name_errors.line_number": "linijka w której błąd został popełniony",
  "correct_naming.class_name_errors": "błędy w nazwach klas:",
  "correct_naming.class_name_errors.code": "kod błędu i opis ze style guide'u języka",
  "correct_naming.class_name_errors.class_name": "nazwa klasy z błędem",
  "correct_naming.class_name_errors.line_number": "linijka w której błąd został popełniony",
  //
  "oop_quality": "Jakość OOP",
  "oop_quality.composition_over_inharitance": "kompozycja ponad dziedziczenie",
  "oop_quality.coupling": "coupling",
  "oop_quality.constructor_number": "liczba konstruktorów",
  //
  "orig_file_output": "wyjście skryptu usera",
  "comp_file_output": "wyjście skryptu taska",
}

export const CaindidateAccordion = ({
                                      candidate,
                                      OnOpenClick,
                                      children,
                                      userSolutions,
                                      isAccordionOpen = false,
                                      postId,
                                      status,
                                      cv,
                                    }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isAccordionOpen);
  const [newStatus, setNewStatus] = useState<ApplicationStatus>();
  const [detailedEvaluations, setDetailedEvaluations] = useState<IDetailedEvaluationsData[]>([]);

  const navigate = useNavigate();
  const handleClick = () => {
    OnOpenClick && OnOpenClick();
    setIsOpen((prevState) => !prevState);
  };

  const handleFetchDetailedEvaluations = async () => {
    userSolutions.forEach(el => {
      el.id && getDetailedEvaluations(el.id)
        .then(res => {
          res.data[0] && setDetailedEvaluations(prevState => {
            return [...prevState, res.data[0]]
          })
        })
    })
  }

  useEffect(() => {
    handleFetchDetailedEvaluations()
  }, [])

  const handleUpdatePostStatus = async (status: string) => {
    if (candidate.id && postId) {
      const {data} = await updatePostStatus(
        {user_id: candidate.id, post_id: postId},
        {user_post_status: {status}}
      );

      if (data) {
        setNewStatus(data.status);

        toast.success("Zaktualizowano status apliakcji.");
      }
    }
  };

  const calcAveragePercentage = (val: number[]) => {
    let res = 0
    val.forEach(el => res += el)
    return (res / val.length).toFixed(1) + "%"
  }

  return (
    <>
      <StyledCard>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Flex
            alignItems="center"
            onClick={() => {
              navigate(`../../../app/user-details/${candidate?.id}`);
            }}
            flexWrap="wrap"
          >
            <Avatar/>
            <P variant="body" fontWeight={500} ml={3}>
              {candidate.email}
            </P>
            {/*<Pie percentage={85} colour="blue"/>*/}
            {/*<Pie percentage={40} colour="orange"/>*/}
            {/*<Pie percentage={35} colour="red"/>*/}
            {/*<Pie percentage={60} colour="green"/>*/}
          </Flex>

          <Flex alignItems="center" flexWrap="wrap">
            {newStatus ? translation[newStatus] : status && translation[status]}
          </Flex>

          <Flex alignItems="center" flexWrap="wrap">
            <Box mr={3} mt={2}>
              <Button variant="lightBlue">
                <a rel="noreferrer" target="_blank" href={`${API_PHOTO_URL}${cv}`}>CV</a>
              </Button>
            </Box>
            <Box mr={3} mt={2}>
              <Button
                variant="lightBlue"
                onClick={() => {
                  navigate(`tasks/${candidate?.id}`);
                }}
              >
                Zadania
              </Button>
            </Box>

            <AccordionIcon onClick={handleClick}/>
          </Flex>
        </Flex>
      </StyledCard>
      <StyledFlex isOpen={isOpen} flexDirection="column">
        <Flex flexWrap="wrap" justifyContent="flex-end" alignItems="center">
          <Flex mt={25} ml={4} flexWrap="wrap">
            <Box mr={4} mb={3}>
              <Button
                variant="lightBlue"
                onClick={() => {
                  handleUpdatePostStatus("finished");
                }}
              >
                Zatwierdź do nast. etapu
              </Button>
            </Box>
            <Box mb={3}>
              <Button
                variant="lightBlue"
                onClick={() => {
                  handleUpdatePostStatus("reviewed");
                }}
              >
                Odrzuć
              </Button>
            </Box>
          </Flex>
        </Flex>

        <Flex>
          {detailedEvaluations?.map((el, index) =>
            <div key={el?.id}>
              <H3
                variant="h3">Zadanie rekrutacyjne
                nr {index + 1}: {el?.user_solution.post.tasks.find(task => task?.id === el?.user_solution?.task_id)?.name} ({calcAveragePercentage([
                  el.body.algorithmic_thinking.score * 10,
                  el.body.correct_structure.score * 10,
                  el.body.correct_naming.score * 10,
                  el.body.oop_quality.score * 10
                ])})</H3>
              <Flex alignItems="center">
                <Pie percentage={el.body.algorithmic_thinking.score * 10} colour="blue"/>
                <div>
                  <P variant="body">{scoreData["algorithmic_thinking"]}</P>
                  <P variant="body2">
                    {scoreData["algorithmic_thinking.run_time"]}: {el?.body?.algorithmic_thinking?.run_time?.toFixed(4)}
                  </P>
                  <P variant="body2">
                    {scoreData["algorithmic_thinking.code_length"]}: {el.body.algorithmic_thinking.code_length}
                  </P>
                  <P variant="body2">
                    {scoreData["algorithmic_thinking.is_correct_output"]}: {el.body.algorithmic_thinking.is_correct_output ? 'tak' : 'nie'}
                  </P>
                </div>
              </Flex>
              <Flex alignItems="center">
                <Pie percentage={el.body.correct_structure.score * 10} colour="orange"/>
                <div>
                  <P variant="body">{scoreData["correct_structure"]}</P>
                  <P variant="body2">
                    {scoreData["correct_structure.function_number"]}: {el.body.correct_structure.function_number}
                  </P>
                  <P variant="body2">
                    {scoreData["correct_structure.class_number"]}: {el.body.correct_structure.class_number}
                  </P>
                </div>
              </Flex>


              <Flex alignItems="center">
                <Pie percentage={el.body.correct_naming.score * 10} colour="red"/>
                <div>
                  <P variant="body">{scoreData["correct_naming"]}</P>
                  <P variant="body2">
                    {scoreData["correct_naming.function_name_errors"]}
                  </P>
                  {el?.body?.correct_naming?.function_name_errors?.map((error, index) => {
                    return <P variant="body2" key={index + 'errorFunc'}>
                      {scoreData["correct_naming.function_name_errors.code"]} - {error?.code}: {error?.name} / {scoreData["correct_naming.function_name_errors.function_name"]} - {error?.function_name} / {scoreData["correct_naming.function_name_errors.line_number"]} - {error?.line_number}
                    </P>
                  })}
                  <P variant="body2">
                    {scoreData["correct_naming.class_name_errors"]}
                  </P>
                  {el?.body?.correct_naming?.class_name_errors?.map((error, index) => {
                    return <P variant="body2" key={index + 'errorClass'}>
                      {scoreData["correct_naming.class_name_errors.code"]} - {error?.code}: {error?.name} / {scoreData["correct_naming.class_name_errors.class_name"]} - {error?.class_name} / {scoreData["correct_naming.class_name_errors.line_number"]} - {error?.line_number}
                    </P>
                  })}
                </div>
              </Flex>
              <Flex alignItems="center" mb={2}>
                <Pie percentage={el.body.oop_quality.score * 10} colour="green"/>
                <div>
                  <P variant="body">{scoreData["oop_quality"]}</P>
                  <P variant="body2">
                    {scoreData["oop_quality.coupling"]}: {el.body.oop_quality.coupling}
                  </P>
                  <P variant="body2">
                    {scoreData["oop_quality.constructor_number"]}: {el.body.oop_quality.constructor_number}
                  </P>
                  <P variant="body2">
                    {scoreData["oop_quality.composition_over_inharitance"]}: {el.body.oop_quality.composition_over_inharitance}
                  </P>
                </div>
              </Flex>
              <hr/>
            </div>
          )}
        </Flex>
      </StyledFlex>
    </>
  );
};
