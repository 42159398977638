import { PAGES } from "constants/paths";
import { IPagesRes } from "types/pages";
import { useFetchAPI } from "./useFetchAPI";

export const useFetchPages = () => {
  const { data } = useFetchAPI<IPagesRes>(PAGES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return data?.data.map((page) => ({
    ...page,
    path: `/info/${page.slug}`,
    private: false,
  }));
};
