import styled from "styled-components";
import DatePicker from "react-datepicker";
import { rgba } from "utilities/rgba";

interface CustomDatePickerProps {
  value: Date;
  onChange: (...event: any[]) => void;
}

export const StyledDatePicker = styled(DatePicker)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.lightGrey};

    &:focus {
      border-radius: 4px;
      border-color: ${theme.palette.primary.main};
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};
    }
  `}

  border-radius: 6px;
  outline: none;
  display: block;
  width: 100%;
  height: 39px;
  padding: 0 12px;
  font-size: 13px;
`;

export const CustomDatePicker = ({
  value,
  onChange,
}: CustomDatePickerProps) => (
  <StyledDatePicker
    selected={value ? new Date(value) : undefined}
    onChange={(date) => {
      onChange(date instanceof Date ? date : "");
    }}
    dateFormat="dd-MM-yyyy"
    portalId="root-datepicker-portal"
  />
);
