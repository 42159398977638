import { Flex, Card, Avatar, Box } from "components";
import { P } from "components/Typography/Typography";
import { ColouredBox } from "components/ColouredBox";
import { Button } from "components/_form";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getUser } from "services/auth";
import { IUser } from "types/user";
import { API_PHOTO_URL } from "constants/paths";

export const ProfileView = (): JSX.Element => {
  const [userData, setUserData] = useState<IUser | null>();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const fetchCandidateData = async (id: string) => {
    const { data } = await getUser(id);

    setUserData(data);
  };

  useEffect(() => {
    id && fetchCandidateData(id);
  }, [id]);

  return (
    <Card>
      <Flex flexWrap="wrap">
        <Flex flexWrap="wrap">
          <Avatar
            size="large"
            url={`${API_PHOTO_URL}${userData?.profile?.avatar}`}
          />
          <Flex width="100%" mt={2}>
            <Box>
              <Button onClick={() => navigate(`settings`)}>Ustawienia</Button>
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection="column" flex="auto" flexWrap="wrap" mt={2}>
          <ColouredBox p={3} width="90%">
            <P variant="body" mb={3}>
              {userData?.profile?.first_name} {userData?.profile?.last_name}
            </P>
            <P variant="body" mb={3}>
              Miejscowość: {userData?.profile?.town}
            </P>
            <P variant="body">telefon: {userData?.profile?.phone_number}</P>
            <P variant="body">mail: {userData?.email}</P>
          </ColouredBox>
          <ColouredBox mt={3} p={3} width="90%">
            <P variant="body" mb={3}>
              Skills
            </P>

            <Flex>
              {userData?.technologies.map(({ name, id }) => (
                <P key={id} variant="body" mr={4}>
                  {name}
                </P>
              ))}
            </Flex>
          </ColouredBox>
          <ColouredBox mt={3} p={3} width="90%">
            <P variant="body">Osiągniecia / Certyfikaty</P>

            {userData?.profile?.certificates.map(({ name, id }) => (
              <P key={id} variant="body" mr={4}>
                {name}
              </P>
            ))}
          </ColouredBox>
        </Flex>
      </Flex>
    </Card>
  );
};
