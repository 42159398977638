import { http } from "utilities/http";
import qs from "qs";

import {
  POSTS,
  POST,
  POSTS_STATUSES,
  PUBLIC_POSTS,
  PUBLIC_POST,
  USER_SOLUTIONS,
  POST_TASKS,
  POST_STATUS, DETAILED_EVALUATIONS,
} from "constants/paths";
import { IPost, IPosts, IUserPostStatus, IUserPostStatuses } from "types/post";
import {
  ICreatePost,
  ICreatePostStatus,
  ISendUserSolutions,
  IUpdatePostStatus,
} from "types/forms/post";
import {IDetailedEvaluations, IUserTasks} from "types/task";
import { serialize } from "object-to-formdata";
import {IFiltersData} from "../components/Filters/Filters";

export const getPost = (id: number | string, isPublic = false) =>
  http<IPost>(isPublic ? PUBLIC_POST(id) : POST(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const getPosts = async (
  filters?: IFiltersData & {
    page?: string | number;
    per_page?: string | number;
  },
  isPublic = false
) => {
  const query = qs.stringify(filters, { arrayFormat: 'brackets' });

  return await http<IPosts>(`${isPublic ? PUBLIC_POSTS : POSTS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createPost = (post: ICreatePost) =>
  http<IPost>(POSTS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(post),
  });

export const updatePost = (id: string, post: ICreatePost) =>
  http<IPost>(POST(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(post),
  });

export const deletePost = (id: string) =>
  http<IPost>(POST(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });

export const createPostStatus = (postStatus: ICreatePostStatus) =>
  http<{ data: IUserPostStatus }>(POSTS_STATUSES, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(postStatus),
  });

export const getPostStatuses = (params: {
  user_id?: number;
  post_id?: number;
}) => {
  const query = qs.stringify(params);

  return http<IUserPostStatuses>(`${POSTS_STATUSES}?${query}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getPostStatus = (params: { user_id: number; post_id: number }) => {
  const query = qs.stringify(params);

  return http<{ data: IUserPostStatus }>(`${POST_STATUS}?${query}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const updatePostStatus = (
  filters: { user_id: number; post_id: number },
  postStatus: IUpdatePostStatus
) => {
  const query = qs.stringify(filters);

  return http<{ data: IUserPostStatus }>(`${POST_STATUS}?${query}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(postStatus),
  });
};

export const deletePostStatus = (details: {
  user_id: number;
  post_id: number;
}) => {
  const query = qs.stringify(details);

  return http(`${POST_STATUS}?${query}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getPostTasks = (postId: string) =>
  http<IUserTasks>(POST_TASKS(postId), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const postUserSolutions = (user_solution: ISendUserSolutions) =>
  http(USER_SOLUTIONS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(user_solution),
  });

export const getUserSolutions = (filters?: {
  filter?: string;
  task_id?: string;
  user_post_status_id?: string;
}) => {
  const query = qs.stringify(filters);

  return http(`${USER_SOLUTIONS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getDetailedEvaluations = (id: string | number) => {
  return http<IDetailedEvaluations>(`${DETAILED_EVALUATIONS}?user_solution_id=${id}&page=1&per_page=1`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
