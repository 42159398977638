import { Box, Flex } from "components";
import { H2, P } from "components/Typography/Typography";
import { IPost } from "types/post";
import { Button } from "components/_form";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { device } from "config/theme";
import { ColouredBox } from "components/ColouredBox";
import { useAuthStateContext } from "contexts/AuthContext";
import { useEffect, useState } from "react";
import { getPostStatuses } from "services/post";

const HalfScreenBox = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.lightBlue};
  `}

  border-radius: 4px;
  width: 49%;

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const PostDetails = ({
  responseData,
}: {
  responseData: IPost["data"];
}) => {
  const navigate = useNavigate();
  const { userProfile } = useAuthStateContext();
  const [appliedOnPost, setAppliedOnPost] = useState(false);

  useEffect(() => {
    if (userProfile) {
      getPostStatuses({ user_id: userProfile.id }).then((res) => {
        res.data.map((userPostStatus) => {
          if (userPostStatus.post.id === responseData.id) {
            setAppliedOnPost(true);
          }
        });
      });
    }
  }, []);

  return (
    <>
      <Flex flexWrap="wrap">
        <HalfScreenBox p={3} my={2}>
          <H2 variant="h2" fontWeight={500} mt={3}>
            Wymagania
          </H2>
          <P variant="body" mt={4} color="secondary">
            {responseData?.responsibilities}
          </P>
        </HalfScreenBox>
        <HalfScreenBox p={3} my={2} ml="auto">
          <H2 variant="h2" fontWeight={500} mt={3}>
            Oferujemy
          </H2>
          <P variant="body" mt={4} color="secondary">
            {responseData?.offer}
          </P>
        </HalfScreenBox>
      </Flex>

      <ColouredBox p={3} my={2}>
        <H2 variant="h2" fontWeight={500} mt={3}>
          Opis stanowiska
        </H2>
        <P variant="body" mt={4} color="secondary">
          {responseData?.description}
        </P>
      </ColouredBox>

      {(userProfile?.id !== responseData?.user?.id && userProfile?.role !== 'admin') &&
        (!appliedOnPost ? (
          <Flex justifyContent="end" mt={3}>
            <Button variant="lightBlue" onClick={() => navigate(`apply`)}>
              Aplikuj teraz
            </Button>
          </Flex>
        ) : (
          <Flex justifyContent="end" mt={3}>
            <Button variant="secondary" disabled>
              Zaaplikowałeś już na to ogłoszenie
            </Button>
          </Flex>
        ))}
    </>
  );
};
