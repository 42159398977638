import { TECHNOLOGIES } from "constants/paths";
import { ITechnologies } from "types/technology";
import { useFetchAPI } from "./useFetchAPI";

export const useFetchDictionaryTechnologies = () => {
  const { data } = useFetchAPI<ITechnologies>(TECHNOLOGIES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return {
    dictionaryTechnologies: data?.data.map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    })),
  };
};
