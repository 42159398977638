export const API_URL = process.env.REACT_APP_API_URL;
export const API_PHOTO_URL = process.env.REACT_APP_API_PHOTO_URL;

export const PASSWORD_RESET = `${API_URL}/password`;
export const PROFILE = `${API_URL}/profile`;
export const ME = `${API_URL}/users/me`;
export const REGISTER_URL = `${API_URL}/signup`;
export const LOGIN_URL = `${API_URL}/login`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const USER = (id: string) => `${API_URL}/users/${id}`;

export const GET_INVITATIONS = (id: string | number) =>
  `${API_URL}/invitations?team_id=${id}`;
export const REMOVE_INVITATIONS = (id: string | number) =>
  `${API_URL}/invitations/${id}`;

export const POSTS = `${API_URL}/posts`;
export const POST = (id: string | number) => `${API_URL}/posts/${id}`;
export const POSTS_STATUSES = `${API_URL}/user_post_statuses`;
export const POST_STATUS = `${API_URL}/user_post_status`;

export const USER_SOLUTIONS = `${API_URL}/user_solutions`;
export const DETAILED_EVALUATIONS = `${API_URL}/detailed_evaluations`;
export const POST_TASKS = (id: string) => `${API_URL}/posts/${id}/tasks`;

export const PUBLIC_POSTS = `${API_URL}/public/posts`;
export const PUBLIC_POST = (id: string | number) =>
  `${API_URL}/public/posts/${id}`;

export const CREATE_TASK = `${API_URL}/tasks`;
export const TASKS = `${API_URL}/tasks`;
export const TASK = (id: string | number) => `${API_URL}/tasks/${id}`;

export const TECHNOLOGIES = `${API_URL}/technologies`;

export const REGULATIONS = `${API_URL}/regulations`;

export const REGULATION = (id: string) => `${API_URL}/regulations/${id}`;

export const PAGES = `${API_URL}/pages?page=1`;

export const PAGE = (slug: string) => `${API_URL}/pages/${slug}`;
