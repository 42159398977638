import React, { useEffect, useState } from "react";
import { getPostStatuses } from "services/post";
import { IUserPostStatus } from "types/post";
import { Flex, PostCard, Spinner } from "components";
import { useNavigate } from "react-router-dom";
import { useAuthStateContext } from "contexts/AuthContext";

export const AppliedPosts = () => {
  const [postStatuses, setPostStatuses] = useState<IUserPostStatus[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { userProfile } = useAuthStateContext();

  useEffect(() => {
    setIsLoading(true);

    if (userProfile) {
      getPostStatuses({ user_id: userProfile.id })
        .then((res) => {
          setPostStatuses(res.data);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex justifyContent="space-between" flexWrap="wrap">
          {postStatuses?.map(({ post, status }) => (
            <PostCard
              key={post.id}
              post={post}
              status={status}
              button={{
                text: "Szczegóły",
                onClick: () => navigate(`/app/your-applies/${post.id}/details`),
              }}
            />
          ))}
        </Flex>
      )}
    </>
  );
};
