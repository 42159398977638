import { StyledCard, StyledFlex } from "./Accordion.styled";
import { Flex } from "components/Flex";
import { ReactNode, useState } from "react";
import { P } from "components/Typography/Typography";
import { ReactComponent as BlackArrowDown } from "assets/icons/black-arrow-down.svg";
import { ReactComponent as WhiteArrowUp } from "assets/icons/white-arrow-up.svg";
import { Box } from "components/Box";

interface AccordionProps {
  children?: ReactNode;
  customElement?: ReactNode;
  title: string;
  isAccordionOpen?: boolean;
  OnOpenClick?: (arg?: number) => void;
  customOpen?: boolean;
  customSetOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Accordion = ({
  title,
  OnOpenClick,
  children,
  isAccordionOpen = false,
  customElement,
  customOpen,
  customSetOpen,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isAccordionOpen);

  const handleClick = () => {
    OnOpenClick && OnOpenClick();
    if (customSetOpen) {
      customSetOpen((prevState) => !prevState);
    } else {
      setIsOpen((prevState) => !prevState);
    }
  };

  return (
    <Flex width="100%" flexDirection="column">
      <StyledCard onClick={handleClick} isOpen={isOpen}>
        <Flex alignItems="center">
          <Flex alignItems="center">
            {customElement && <Box mr={2}>{customElement}</Box>}
            <Box mr={3}>{isOpen ? <WhiteArrowUp /> : <BlackArrowDown />}</Box>
          </Flex>
          <P variant="body" fontWeight={500}>
            {title}
          </P>
        </Flex>
      </StyledCard>
      <StyledFlex isOpen={customSetOpen ? customOpen || false : isOpen}>
        {children}
      </StyledFlex>
    </Flex>
  );
};
