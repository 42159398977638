import styled from "styled-components";
import { Card, Flex } from "components";
import { device } from "config/theme";
import { ReactComponent as Illustration } from "assets/img/main-illustration.svg";

export const LogoContent = styled(Flex)`
  align-items: center;
  height: 350px;
  text-align: left;

  > h1 {
    max-width: 500px;
    margin: 40px 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    align-items: center;

    > h1 {
      text-align: center;
    }
  }

  @media ${device.mobile} {
    height: unset;

    > h1 {
      margin: unset;
    }
  }
`;
export const Logo = styled(Illustration)`
  min-width: 300px;
  min-height: 300px;
  @media ${device.laptop} {
    min-width: 200px;
    min-height: 300px;
  }
`;
export const FooterContent = styled(Flex)`
  grid-area: footer;
  @media ${device.laptop} {
    justify-content: center;
  }
  @media ${device.mobile} {
    ${({ theme }) => `
      background-color: ${theme.palette.primary.main};
    `}
  }
`;
export const ViewContent = styled(Flex)`
  grid-area: content;
  > ${Card} {
    border-radius: 0px;
    overflow: auto;
  }
`;

export const Wrapper = styled.main`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.secondary};
  `}
  display: grid;
  height: 100vh;

  grid-template-columns: 5fr 2fr;
  grid-template-rows: 1fr 10fr 2fr;
  grid-template-areas:
    "navbar navbar"
    "description content"
    "footer content";

  > ${LogoContent}, ${FooterContent} {
    padding: 100px;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;

    > ${LogoContent}, ${FooterContent} {
      padding: 30px;
    }
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "navbar"
      "description"
      "content"
      "footer";
  }
`;
