import React, { useEffect, useRef, useState } from "react";
import { Flex, Card, Box, Avatar } from "components";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { Button, TextareaField, TextField } from "components/_form";
import {
  useAuthDispatchContext,
  useAuthStateContext,
} from "contexts/AuthContext";
import { device, theme } from "config/theme";
import { useForm } from "react-hook-form";
import { changeProfile, getUser } from "services/auth";
import { toast } from "react-toastify";
import { API_PHOTO_URL } from "constants/paths";

const ColouredBox = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.lightBlue};
  `}
  border-radius: 10px;
  margin-left: 10px;

  @media ${device.tablet} {
    margin-left: 0;
  }
`;

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const ResponsiveFlex = styled(Flex)`
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

const ResponsiveBox = styled(Box)`
  width: 49%;
  padding: 5px;

  @media ${device.tablet} {
    padding: 0;
    width: 100%;
  }
`;

interface IProfileFormData {
  first_name: string;
  last_name: string;
  town: string;
  phone_number: string;
  skills: string;
  certificates: string[];
  avatar: Blob;

  password: string;
  password_confirmation: string;
}

export const ProfileSettingsView = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [preview, setPreview] = React.useState<string>();

  const { userProfile } = useAuthStateContext();
  const { setUserProfile } = useAuthDispatchContext();

  const { register, handleSubmit, reset } = useForm<IProfileFormData>();

  const onSubmit = async (data: IProfileFormData) => {
    try {
      const response = await changeProfile({ ...data, avatar: uploadedFile });
      setUserProfile(response);

      toast.success("Zmieniono dane profilu.");
    } catch (err: any) {
      toast.error("Nie udało się zaktualizować danych");
    }
  };

  const fetchCandidateData = async (id: string) => {
    const { data } = await getUser(id);

    const { certificates, avatar, ...rest } = data.profile;

    reset(rest);
  };

  useEffect(() => {
    id && fetchCandidateData(id);
  }, [id]);

  useEffect(() => {
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      setPreview("");
    }
  }, [uploadedFile]);

  return (
    <Card>
      <Flex flexWrap="wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ResponsiveFlex>
            <Box mb={2}>
              <Avatar
                size="large"
                url={
                  preview || `${API_PHOTO_URL}${userProfile?.profile?.avatar}`
                }
              />
              <Box mt={2}>
                <Button
                  fullWidth
                  variant="lightBlue"
                  onClick={(event) => {
                    event.preventDefault();
                    fileInputRef.current?.click();
                  }}
                >
                  {uploadedFile ? uploadedFile.name : "Zmień zdjęcie"}
                </Button>
              </Box>

              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                multiple
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(event) => {
                  if (event.target.files) {
                    setUploadedFile(event.target.files[0]);
                  }
                }}
              />
            </Box>

            <Flex flexWrap="wrap">
              <ColouredBox p={3} width="100%">
                <Flex flexWrap="wrap">
                  {userProfile?.role === "candidate" ? (
                    <>
                      <ResponsiveBox>
                        <TextField
                          bgColor={theme.palette.neutral.white}
                          label="Imię"
                          {...register("first_name")}
                        />
                      </ResponsiveBox>
                      <ResponsiveBox>
                        <TextField
                          bgColor={theme.palette.neutral.white}
                          label="Nazwisko"
                          {...register("last_name")}
                        />
                      </ResponsiveBox>
                    </>
                  ) : (
                    <ResponsiveBox>
                      <TextField
                        bgColor={theme.palette.neutral.white}
                        label="Nazwa firmy"
                        {...register("first_name")}
                      />
                    </ResponsiveBox>
                  )}

                  <ResponsiveBox>
                    <TextField
                      bgColor={theme.palette.neutral.white}
                      label="Miejscowość"
                      {...register("town")}
                    />
                  </ResponsiveBox>
                  <ResponsiveBox>
                    <TextField
                      bgColor={theme.palette.neutral.white}
                      label="telefon"
                      {...register("phone_number")}
                    />
                  </ResponsiveBox>
                  <Box width="100%" m={3}>
                    <Button
                      type="button"
                      onClick={() => {
                        navigate("/password-reset");
                      }}
                    >
                      Zmień Hasło
                    </Button>
                  </Box>
                </Flex>
              </ColouredBox>

              {userProfile?.role === "candidate" && (
                <>
                  <ColouredBox mt={4} ml={4} p={3} width="100%" flexWrap="wrap">
                    <TextField
                      bgColor={theme.palette.neutral.white}
                      label="Umiejętności po przecinku"
                    />
                  </ColouredBox>
                  <ColouredBox
                    height="200px"
                    mt={4}
                    ml={4}
                    p={3}
                    width="100%"
                    flexWrap="wrap"
                  >
                    <TextareaField
                      bgColor={theme.palette.neutral.white}
                      label="Osiągniecia / Certyfikaty"
                    />
                  </ColouredBox>
                </>
              )}
            </Flex>
          </ResponsiveFlex>
          <StyledFlex justifyContent="end" alignItems="end" mt={4}>
            <Button type="submit">Zapisz</Button>
          </StyledFlex>
        </form>
      </Flex>
    </Card>
  );
};
