import { Outlet, RouteObject } from "react-router-dom";

import { LoginView } from "views/auth/LoginView";
import { ContactView } from "views/projectData/ContactView/ContactView";
import { RegisterView } from "views/auth/RegisterView";
import { ResetPasswordView } from "views/auth/ResetPasswordView";
import { ChangePasswordView } from "views/auth/ChangePasswordView";
import { PostsView } from "views/PostsView";
import { AppLayout } from "views/layouts/App/AppLayout";
import { GuestLayout } from "views/layouts/Guest/GuestLayout";
import { AppliedPosts } from "views/AppliedPosts";
import { PostFormView } from "views/PostFormView";
import { PostView } from "views/PostView/PostView";
import { ApplyDetailsView } from "views/ApplyDetailsView";

// tmp
import { CodeEditor } from "components";
import { ApplyingPostView } from "views/ApplyingPostView";
import { ProfileView } from "views/Profile/ProfileView";
import { TasksView } from "views/TasksView";
import { DoneTasksView } from "views/DoneTasksView";
import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import { ProfileSettingsView } from "views/Profile/ProfileSettingsView";
import { CustomView } from "views/projectData/CustomView";

interface IRoute extends RouteObject {
  breadcrumb?: BreadcrumbsRoute["breadcrumb"];
  children?: IRoute[];
}

export const routes: IRoute[] = [
  {
    path: "/",
    element: (
      <GuestLayout>
        <Outlet />
      </GuestLayout>
    ),
    children: [
      {
        index: true,
        element: <LoginView />,
      },
      {
        path: "/signup",
        element: <RegisterView />,
      },
      {
        path: "/password-reset",
        element: <ResetPasswordView />,
      },
      {
        path: "/password-reset/new-password/:token",
        element: <ChangePasswordView />,
      },
    ],
  },
  {
    path: "/info",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: ":slug",
        element: <CustomView />,
      },
      {
        path: "contact",
        element: <ContactView />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "offers",
        element: <PostsView key="active" />,
      },
      {
        path: "active-offers",
        element: <PostsView key="active" filter="available" />,
      },
      {
        path: "my-offers",
        element: <PostsView key="active" />,
      },
      {
        path: "my-tasks",
        element: <TasksView />,
      },
      {
        path: "active-offers/:id",
        element: <PostView />,
      },
      {
        path: "active-offers/:id/tasks/:candidateId",
        element: <DoneTasksView />,
      },
      {
        path: "active-offers/:id/apply",
        element: <ApplyingPostView />,
      },
      {
        path: "your-applies",
        element: <AppliedPosts />,
      },
      {
        path: "your-applies/:id/details",
        element: <ApplyDetailsView />,
      },
      // tmp
      {
        path: "code-edit",
        element: <CodeEditor />,
      },
      // firm
      {
        path: "add-post",
        element: <PostFormView />,
      },
      {
        path: "add-post/:id",
        element: <PostFormView />,
      },
      {
        path: "user-details/:id",
        element: <ProfileView />,
      },
      {
        path: "user-details/:id/settings",
        element: <ProfileSettingsView />,
      },
    ],
  },
];
