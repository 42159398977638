import { Card } from "components/Card";
import { Flex } from "components/Flex";
import { P } from "components/Typography/Typography";
import { device } from "config/theme";
import styled from "styled-components";

interface IStyledFlex {
  isOpen: boolean;
}

export const StyledCard = styled(Card)`
  margin: 12px 0;
  padding: 12px 20px;
  cursor: pointer;
`;

export const StyledFlex = styled(Flex)<IStyledFlex>`
  ${({ theme, isOpen }) => `
    margin: -10px 32px 32px;
    padding: 8px 20px;
    display: ${isOpen ? "flex" : "none"};
    background-color: ${theme.palette.neutral.veryLight};

    @media ${device.tablet} {
      margin: -10px 10px 10px;
    }
  `};
`;

export const StyledP = styled(P)`
  @media ${device.tablet} {
  }
`;
